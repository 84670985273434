import { Dictionary } from "lodash";

import { Network } from "models";

import ProductionScanner from "helpers/ProductionScanner";

import ethereumLogo from "assets/ethereum-eth-logo.svg";
import binanceLogo from "assets/binance-coin-bnb-logo.svg";
import polygonLogo from "assets/polygon2.svg";
import auroraLogo from "assets/aurora.png";
import ethereumContainedLogo from "assets/ethereum.svg";
import binanceContainedLogo from "assets/bsc.svg";
import polygonContainedLogo from "assets/polygon.svg";
import auroraContainedLogo from "assets/aurora2.png";

const IS_PRODUCTION = ProductionScanner.check();

const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY || "";

const AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM)
  : 2254114;
const AMOUNT_OF_BLOCKS_PER_YEAR_BSC: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_BSC
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_BSC)
  : 10500000;
const AMOUNT_OF_BLOCKS_PER_YEAR_POLYGON: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_POLYGON
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_POLYGON)
  : 10500000;
const AMOUNT_OF_BLOCKS_PER_YEAR_AURORA: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_AURORA
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_AURORA)
  : 24258500;

export const ETHEREUM_MAINNET_NETWORK: Network = {
  name: "Ethereum",
  alias: "Ethereum Mainnet",
  networkId: 1,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM,
  rpcUrl: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
  nativeCurrency: {
    name: "Ethereum Coin",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl: "https://etherscan.io",
};

export const BSC_MAINNET_NETWORK: Network = {
  name: "BSC Mainnet",
  alias: "BNB Smart Chain Mainnet",
  networkId: 56,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_BSC,
  rpcUrl: "https://bsc-dataseed1.binance.org",
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrl: "https://bscscan.com",
};

export const POLYGON_MAINNET_NETWORK: Network = {
  name: "Polygon Mainnet",
  alias: "Polygon Mainnet",
  networkId: 137,
  avatar: polygonLogo,
  avatarContained: polygonContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_POLYGON,
  rpcUrl: "https://rpc-mainnet.maticvigil.com",
  nativeCurrency: {
    name: "Polygon Coin",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrl: "https://polygonscan.com",
};

export const AURORA_MAINNET_NETWORK: Network = {
  name: "Aurora Mainnet",
  alias: "Aurora Mainnet",
  networkId: 1313161554,
  avatar: auroraLogo,
  avatarContained: auroraContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_AURORA,
  rpcUrl: "https://mainnet.aurora.dev",
  nativeCurrency: {
    name: "Ethereum Coin",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl: "https://aurorascan.dev",
};

export const RINKEBY_NETWORK: Network = {
  name: "Rinkeby",
  alias: "Rinkeby",
  networkId: 4,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM,
  rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_API_KEY}`,
  nativeCurrency: {
    name: "Ethereum Coin",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl: "https://rinkeby.etherscan.io",
};

export const BSC_TEST_NETWORK: Network = {
  name: "BSC Testnet",
  alias: "BNB Smart Chain Testnet",
  networkId: 97,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_BSC,
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrl: "https://testnet.bscscan.com",
};

export const POLYGON_TESTNET_NETWORK: Network = {
  name: "Polygon Testnet",
  alias: "Polygon Testnet",
  networkId: 80001,
  avatar: polygonLogo,
  avatarContained: polygonContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_POLYGON,
  rpcUrl: "https://rpc-mumbai.maticvigil.com",
  nativeCurrency: {
    name: "Polygon Coin",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrl: "https://mumbai.polygonscan.com",
};

export const AURORA_TESTNET_NETWORK: Network = {
  name: "Aurora Testnet",
  alias: "Aurora Testnet",
  networkId: 1313161555,
  avatar: auroraLogo,
  avatarContained: auroraContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_AURORA,
  rpcUrl: "https://testnet.aurora.dev",
  nativeCurrency: {
    name: "Ethereum Coin",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl: "https://testnet.aurorascan.dev",
};

const NETWORKS: Dictionary<Network> = {
  // [ETHEREUM_MAINNET_NETWORK.networkId]: ETHEREUM_MAINNET_NETWORK,
  // [BSC_MAINNET_NETWORK.networkId]: BSC_MAINNET_NETWORK,
  // [POLYGON_MAINNET_NETWORK.networkId]: POLYGON_MAINNET_NETWORK,
  // [AURORA_MAINNET_NETWORK.networkId]: AURORA_MAINNET_NETWORK,
};

if (!IS_PRODUCTION) {
  // NETWORKS[RINKEBY_NETWORK.networkId] = RINKEBY_NETWORK;
  NETWORKS[BSC_TEST_NETWORK.networkId] = BSC_TEST_NETWORK;
  // NETWORKS[POLYGON_TESTNET_NETWORK.networkId] = POLYGON_TESTNET_NETWORK;
  // NETWORKS[AURORA_TESTNET_NETWORK.networkId] = AURORA_TESTNET_NETWORK;
}

export { NETWORKS };

export const DEFAULT_NETWORK = BSC_TEST_NETWORK;
