import { FC, useState, useCallback } from "react";

import { Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Pool } from "models";
import { usePoolMutations } from "hooks/mutations";
import { useModal } from "hooks";
import {
  Button,
  NumericText,
  Modal,
  LoadingModal,
  NumericTextField,
  EarningsGrid,
} from "components";
import styles from "./WithdrawModal.module.scss";

interface WithdrawModalProps {
  pool: Pool;
}

const WithdrawModal: FC<WithdrawModalProps> = (props) => {
  const {
    pool: {
      balance,
      stake,
      dailyEarnings,
      monthlyEarnings,
      yearlyEarnings,
      liquidityTokenAddress,
      contractAddress,
      poolIndex,
      depositToken,
      rewardToken,
      depositTokenPrice,
      rewardTokenPrice,
    },
  } = props;

  const { withdraw, withdrawLoading } = usePoolMutations(
    liquidityTokenAddress,
    contractAddress,
    poolIndex
  );
  const { isOpen, onOpen, onClose } = useModal();
  const [inputValue, setInputValue] = useState<string>("");

  const maxValue = stake;
  const isDisabledButton =
    !inputValue ||
    !Number(inputValue) ||
    Number(inputValue) > Number(maxValue) ||
    withdrawLoading;

  const withdrawCallback = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleWithdraw = async () => {
    if (!isDisabledButton) {
      try {
        onOpen();
        await withdraw({
          value: inputValue,
          callback: withdrawCallback,
        });
        setInputValue("");
      } catch (error) {
        console.log(error);
        onClose();
      }
    }
  };

  return (
    <>
      <div className={styles.depositWrapper}>
        <h4 className={styles.title}>Withdraw</h4>

        <Row gutter={[0, 10]}>
          <Col span={24} className={styles.amountWrapper}>
            <span>Amount</span>
            <span className={styles.balance}>
              Staked Amount: <NumericText value={stake} precision={3} />
            </span>
          </Col>

          <Col span={24}>
            <NumericTextField
              value={inputValue}
              onChange={setInputValue}
              maxValue={maxValue}
              price={depositTokenPrice}
              depositToken={depositToken}
              rewardToken={rewardToken}
              readonly={withdrawLoading}
            />
          </Col>
        </Row>

        <EarningsGrid
          title="Your Balance"
          value={balance}
          dailyEarnings={dailyEarnings}
          monthlyEarnings={monthlyEarnings}
          yearlyEarnings={yearlyEarnings}
          rewardToken={rewardToken}
          rewardTokenPrice={rewardTokenPrice}
        />

        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Button
              className={styles.button}
              onClick={handleWithdraw}
              disabled={isDisabledButton}
            >
              {withdrawLoading && (
                <LoadingOutlined
                  style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
                />
              )}
              <span>Withdraw</span>
            </Button>
          </Col>
        </Row>
      </div>

      <Modal visible={isOpen} onCancel={onClose} width={450} centered>
        <LoadingModal
          closeHandler={onClose}
          title="Withdraw Transaction"
          text="Please, sign transaction in your wallet"
        />
      </Modal>
    </>
  );
};

export default WithdrawModal;
