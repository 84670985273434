import clsx from "clsx";
import { Col, Row } from "antd";

import { convertToPrice } from "helpers/formatting";
import { NumericText } from "components";
import { Token } from "models";

import styles from "./EarningsGrid.module.scss";

type EarningsGridProps = {
  title: string;
  value: string | number;
  dailyEarnings: number;
  monthlyEarnings: number;
  yearlyEarnings: number;
  rewardToken: Token;
  rewardTokenPrice: number;
};

const EarningsGrid = ({
  title,
  value,
  dailyEarnings,
  monthlyEarnings,
  yearlyEarnings,
  rewardToken,
  rewardTokenPrice,
}: EarningsGridProps) => (
  <Row gutter={[0, 10]} className={styles.grid}>
    <Col span={24}>
      <span className={clsx(styles.title, styles.gridTitle)}>{title}</span>
      <span className={styles.value}>
        <NumericText value={value} />
      </span>
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Daily Earnings</span>
      <NumericText
        value={dailyEarnings}
        suffix={rewardToken.symbol}
        className={styles.earningsMainCurrency}
      />
      <NumericText
        value={convertToPrice(dailyEarnings, rewardTokenPrice)}
        prefix="$"
        className={styles.earningsConverted}
      />
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Monthly Earnings</span>
      <NumericText
        value={monthlyEarnings}
        suffix={rewardToken.symbol}
        className={styles.earningsMainCurrency}
      />
      <NumericText
        value={convertToPrice(monthlyEarnings, rewardTokenPrice)}
        prefix="$"
        className={styles.earningsConverted}
      />
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Yearly Earnings</span>
      <NumericText
        value={yearlyEarnings}
        suffix={rewardToken.symbol}
        className={styles.earningsMainCurrency}
      />
      <NumericText
        value={convertToPrice(yearlyEarnings, rewardTokenPrice)}
        prefix="$"
        className={styles.earningsConverted}
      />
    </Col>
  </Row>
);

export default EarningsGrid;
