import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { WalletProvider } from "context";
import { Layout } from "components";
import { Farming, Gifts, SnapDemo } from "views";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// eslint-disable-next-line arrow-body-style
const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <Layout>
          <SnapDemo />
          <Farming />
          <Gifts />
        </Layout>
      </WalletProvider>
    </QueryClientProvider>
  );
};

export default App;
