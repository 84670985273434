import { FARMS } from "config";
import { VerifiableDesc } from "models/types";

class VerifiableHelper {
  public static getDescription(
    contractAddress: string
  ): VerifiableDesc | undefined {
    const verifiableFarm = FARMS.find(
      (item) =>
        item.contractAddress.toLowerCase() === contractAddress.toLowerCase()
    );

    return verifiableFarm?.verifiableDesc;
  }
}

export default VerifiableHelper;
