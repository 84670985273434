import { FC, useState, useCallback } from "react";
import { Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PureFIRuleErrorCodes, VerifyRuleResponse } from "@purefi/verifier-sdk";
import { errorCodes, serializeError } from "eth-rpc-errors";
import { Pool } from "models";
import { VerifiableDesc } from "models/types";
import { usePoolMutations } from "hooks/mutations";
import { useModal } from "hooks";
import {
  Button,
  NumericText,
  Modal,
  EligibleModal,
  NumericTextField,
  EarningsGrid,
  LoadingModal,
} from "components";
import VerifiableHelper from "helpers/VerifiableHelper";
import { openErrorNotification } from "components/Notification";
import warningIcon from "assets/risk/warning.svg";

import styles from "./DepositModal.module.scss";

type DepositModalProps = {
  pool: Pool;
};

const DepositModal: FC<DepositModalProps> = (props) => {
  const {
    pool: {
      balance,
      stake,
      dailyEarnings,
      monthlyEarnings,
      yearlyEarnings,
      liquidityTokenAddress,
      contractAddress,
      allowance,
      poolIndex,
      allowedAmountToDeposit,
      depositToken,
      rewardToken,
      depositTokenPrice,
      rewardTokenPrice,
      depositNoticeMessage,
    },
  } = props;
  const verifiableDesc = VerifiableHelper.getDescription(contractAddress);

  const {
    approve,
    verifyRule,
    deposit,
    approveLoading,
    verifyRuleLoading,
    depositLoading,
  } = usePoolMutations(liquidityTokenAddress, contractAddress, poolIndex);

  const [forceDisabled, setForceDisabled] = useState(false);

  const {
    isOpen: isOpenVerifyRule,
    onOpen: onOpenVerifyRule,
    onClose: onCloseVerifyRule,
  } = useModal();

  const {
    isOpen: isOpenDeposit,
    onOpen: onOpenDeposit,
    onClose: onCloseDeposit,
  } = useModal();

  const {
    isOpen: isOpenApprove,
    onOpen: onOpenApprove,
    onClose: onCloseApprove,
  } = useModal();

  const {
    isOpen: isOpenEligible,
    onOpen: onOpenEligible,
    onClose: onCloseEligible,
  } = useModal();

  const {
    isOpen: isOpenDepositLimit,
    onOpen: onOpenDepositLimit,
    onClose: onCloseDepositLimit,
  } = useModal();

  const [inputValue, setInputValue] = useState<string>("");

  const [verifyRuleModalText, setVerifyRuleModalText] = useState(
    "Please, sign message in your wallet"
  );

  const approveCallback = useCallback(() => {
    onCloseApprove();
  }, [onCloseApprove]);

  const verifyRuleCallback = useCallback(() => {
    setVerifyRuleModalText("Please, wait");
  }, [setVerifyRuleModalText]);

  const depositCallback = useCallback(() => {
    onCloseDeposit();
  }, [onCloseDeposit]);

  const maxValue =
    Number(balance) > Number(allowedAmountToDeposit)
      ? allowedAmountToDeposit
      : balance;

  const isDisabledButton =
    !allowance ||
    !inputValue ||
    !Number(inputValue) ||
    Number(inputValue) > Number(maxValue) ||
    forceDisabled ||
    verifyRuleLoading ||
    depositLoading;

  const handleApprove = async () => {
    try {
      onOpenApprove();
      await approve({ callback: approveCallback });
    } catch (error) {
      console.log(error);
      onCloseApprove();
    }
  };

  const verifyRuleHandler = async (
    theVerifiableDesc: VerifiableDesc
  ): Promise<VerifyRuleResponse | null> => {
    try {
      onOpenVerifyRule();
      const response = await verifyRule({
        value: inputValue,
        verifiableDesc: theVerifiableDesc,
        callback: verifyRuleCallback,
      });
      return response;
    } catch (error) {
      const theError = error as any;
      if (theError.name === "PureFIRuleError") {
        if (theError.code === PureFIRuleErrorCodes.NOT_ELIGIBLE) {
          openErrorNotification({
            message: "Your AML risk score exceeds allowed limit",
          });
          setForceDisabled(true);
        } else if (theError.code === PureFIRuleErrorCodes.PARTIALLY_ELIGIBLE) {
          onOpenEligible();
          setForceDisabled(true);
        } else if (theError.code === PureFIRuleErrorCodes.DEPOSIT_LIMIT) {
          onOpenDepositLimit();
          setForceDisabled(true);
        }
      } else {
        const { code } = serializeError(theError);

        if (code === errorCodes.provider.userRejectedRequest) {
          openErrorNotification({
            message: "Message signature has been denied",
          });
        }
      }
      return null;
    } finally {
      onCloseVerifyRule();
      setVerifyRuleModalText("Please, sign message in your wallet");
    }
  };

  const depositHandler = async (
    theVerifiableDesc?: VerifiableDesc,
    ruleResponse?: VerifyRuleResponse
  ) => {
    try {
      onOpenDeposit();
      await deposit({
        value: inputValue,
        callback: depositCallback,
        ruleResponse,
        verifiableDesc: theVerifiableDesc,
      });
      setInputValue("");
    } catch (error) {
      console.log(error);
      onCloseDeposit();
    }
  };

  const manageDeposit = async () => {
    if (typeof verifiableDesc === "undefined") {
      depositHandler();
    } else {
      const ruleResponse = await verifyRuleHandler(
        verifiableDesc as VerifiableDesc
      );
      if (ruleResponse !== null) {
        depositHandler(verifiableDesc, ruleResponse);
      }
    }
  };

  return (
    <div className={styles.depositWrapper}>
      {depositNoticeMessage && (
        <div className={styles.noticeContainer}>
          <img className={styles.noticeIcon} src={warningIcon} alt="Notice" />
          <div className={styles.noticeMessage}>{depositNoticeMessage}</div>
        </div>
      )}
      <h4 className={styles.title}>Deposit</h4>

      <Row gutter={[0, 10]}>
        <Col span={24} className={styles.amountWrapper}>
          <span>Balance</span>
          <span className={styles.balance}>
            <NumericText value={balance} precision={3} />{" "}
            {`${depositToken.symbol}`}
          </span>
        </Col>
        {allowedAmountToDeposit !== Number.POSITIVE_INFINITY.toString() && (
          <Col span={24} className={styles.amountWrapper}>
            <span>Deposit Limit</span>
            <span className={styles.balance}>
              <NumericText value={allowedAmountToDeposit} precision={3} />{" "}
              {`${depositToken.symbol}`}
            </span>
          </Col>
        )}

        <Col span={24}>
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            price={depositTokenPrice}
            depositToken={depositToken}
            rewardToken={rewardToken}
            readonly={
              !allowance ||
              depositLoading ||
              verifyRuleLoading ||
              approveLoading
            }
          />
        </Col>
      </Row>

      <EarningsGrid
        title="You Staked"
        value={stake}
        dailyEarnings={dailyEarnings}
        monthlyEarnings={monthlyEarnings}
        yearlyEarnings={yearlyEarnings}
        rewardToken={rewardToken}
        rewardTokenPrice={rewardTokenPrice}
      />

      <Row gutter={[0, { md: 10, sm: 20, xs: 20 }]}>
        {!allowance && (
          <Col span={24}>
            <Button
              className={styles.button}
              onClick={handleApprove}
              disabled={allowance || approveLoading}
            >
              {approveLoading && (
                <LoadingOutlined
                  style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
                />
              )}
              <span>Approve {depositToken.symbol}</span>
            </Button>
          </Col>
        )}

        {allowance && (
          <Col span={24}>
            <Button
              className={styles.button}
              onClick={manageDeposit}
              disabled={isDisabledButton}
            >
              {(depositLoading || verifyRuleLoading) && (
                <LoadingOutlined
                  style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
                />
              )}
              <span>Deposit</span>
            </Button>
          </Col>
        )}
      </Row>

      <Modal
        visible={isOpenApprove}
        onCancel={onCloseApprove}
        width={450}
        centered
      >
        <LoadingModal
          closeHandler={onCloseApprove}
          title={`Approve ${depositToken.symbol} Transaction`}
          text="Please, sign transaction in your wallet"
        />
      </Modal>

      <Modal
        visible={isOpenVerifyRule}
        onCancel={onCloseVerifyRule}
        width={450}
        centered
      >
        <LoadingModal
          closeHandler={onCloseVerifyRule}
          title="AML/KYC Verification"
          text={verifyRuleModalText}
        />
      </Modal>

      <Modal
        visible={isOpenDeposit}
        onCancel={onCloseDeposit}
        width={450}
        centered
      >
        <LoadingModal
          closeHandler={onCloseDeposit}
          title="Deposit Transaction"
          text="Please, sign transaction in your wallet"
        />
      </Modal>

      {typeof verifiableDesc !== "undefined" && (
        <>
          <Modal
            visible={isOpenEligible}
            onCancel={onCloseEligible}
            width={450}
            centered
          >
            <EligibleModal
              title="Identity Verification"
              text="Your AML risk score exceeds allowed limit, but you are welcome to verify your identity and proceed once you've finished"
              link={`https://kyc.dashboard.purefi.io?projectId=${verifiableDesc.projectId}&chainId=${verifiableDesc.chainId}`}
            />
          </Modal>

          <Modal
            visible={isOpenDepositLimit}
            onCancel={onCloseDepositLimit}
            width={450}
            centered
          >
            <EligibleModal
              title="Identity Verification"
              text="Your deposit stake exceeds allowed limit, but you are welcome to verify your identity and proceed once you've finished"
              link={`https://kyc.dashboard.purefi.io?projectId=${verifiableDesc.projectId}&chainId=${verifiableDesc.chainId}`}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default DepositModal;
