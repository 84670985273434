import styles from "./NoticeText.module.scss";

type NoticeTextProps = {
  label: string;
};

const NoticeText = ({ label }: NoticeTextProps) => (
  <div className={styles.noticeText}>
    <p className={styles.label}>{label}</p>
  </div>
);

export default NoticeText;
