import { FC } from "react";
import { Row, Col, Space } from "antd";
import { PoolCard, SubscribeCard } from "components";
import { Pool } from "models";

interface PoolListProps {
  pools: Pool[];
  isLoading: boolean;
}

const PoolList: FC<PoolListProps> = (props: PoolListProps) => {
  const { pools, isLoading } = props;
  return (
    <Space size={20} direction="vertical">
      {pools.map((pool) => (
        <Row key={`${pool.contractAddress}_${pool.poolIndex}`} gutter={[0, 16]}>
          <Col span={24}>
            {pool.contractAddress !==
            "0x0000000000000000000000000000000000000000" ? (
              <PoolCard pool={pool} isLoading={isLoading} />
            ) : (
              <SubscribeCard pool={pool} />
            )}
          </Col>
        </Row>
      ))}
    </Space>
  );
};

export default PoolList;
