import PriceService from "./priceService";
import DateService from "./dateService";

const priceServiceConfig = {
  baseUrl: process.env.REACT_APP_TOKEN_PRICE_API_URL || "",
};

const dateServiceConfig = {
  baseUrl: process.env.REACT_APP_ETHER_SCAN_API_URL || "",
  apiKey: process.env.REACT_APP_ETHER_SCAN_API_KEY || "",
};

export const priceService = PriceService.getInstance(priceServiceConfig);
export const dateService = DateService.getInstance(dateServiceConfig);
