import { FC, useEffect, useState } from "react";

import { Badge, Tabs } from "antd";

import { useWallet } from "hooks";
import { usePools } from "hooks/query";
import { Loader, PoolList } from "components";
import { Farm, PoolState } from "models";
import { FARMS } from "config";

import styles from "./Farming.module.scss";

const { TabPane } = Tabs;

const Farming: FC = () => {
  const { networkId, activeNetwork } = useWallet();
  const isAvailableNetwork = networkId === activeNetwork.networkId;
  const [farms, setFarms] = useState<Farm[]>([]);

  useEffect(() => {
    if (networkId === activeNetwork.networkId) {
      const farmsByNetworkId = FARMS.filter(
        (farm) => farm.networkId === activeNetwork.networkId
      );
      setFarms(farmsByNetworkId);
    } else {
      setFarms([]);
    }
  }, [networkId, activeNetwork]);

  const { isLoading, isFetching, pools } = usePools(farms);

  const activePools = pools.filter((pool) => pool.state === PoolState.active);
  const upcomingPools = pools.filter(
    (pool) => pool.state === PoolState.upcoming
  );
  const endedPools = pools.filter((pool) => pool.state === PoolState.ended);

  const upcomingTab =
    !isAvailableNetwork || !upcomingPools.length ? (
      "Upcoming"
    ) : (
      <Badge count={upcomingPools.length}>
        <span className={styles.blinkUpcoming}>Upcoming</span>
      </Badge>
    );

  return (
    <>
      <h1 className={styles.title}>Farming</h1>

      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {!isLoading && isAvailableNetwork && (
        <Tabs defaultActiveKey="1" className={styles.tabs}>
          <TabPane tab="Active" key="1">
            <PoolList pools={activePools} isLoading={isFetching} />
          </TabPane>
          <TabPane tab={upcomingTab} key="2">
            <PoolList pools={upcomingPools} isLoading={isFetching} />
          </TabPane>
          <TabPane tab="Ended" key="3">
            <PoolList pools={endedPools} isLoading={isFetching} />
          </TabPane>
        </Tabs>
      )}
    </>
  );
};

export default Farming;
