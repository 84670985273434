import { FC, useEffect, useState } from "react";
import storage from "helpers/storage";

import { Modal } from "components";
import { GiftsModal } from "components/Modals";

import { useModal, useWallet } from "hooks";
import { useGifts } from "hooks/query";
import { Farm, Gift } from "models";
import { FARMS } from "config";

const GIFT_STORAGE_KEY = "purefi_gifts";

const Gifts: FC = () => {
  const { account, networkId, activeNetwork } = useWallet();
  const [forceDisabled, setForceDisabled] = useState(false);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [gifts, setGifts] = useState<Gift[]>([]);
  const { isOpen, onClose } = useModal(true);

  useEffect(() => {
    if (networkId === activeNetwork.networkId) {
      const farmsByNetworkId = FARMS.filter(
        (farm) => farm.networkId === activeNetwork.networkId
      );
      const farmsWithDistributor = farmsByNetworkId.filter((farm) => {
        const distributor = farm?.verifiableDesc?.distributor;
        return typeof distributor !== "undefined";
      });
      setFarms(farmsWithDistributor);
    } else {
      setFarms([]);
    }
  }, [networkId, activeNetwork]);

  const { isLoading, gifts: theGifts } = useGifts(farms);

  useEffect(() => {
    if (account) {
      const allGifts = storage.getValue(GIFT_STORAGE_KEY);
      if (allGifts) {
        const parsedGifts = JSON.parse(allGifts);
        setGifts(parsedGifts);
      }
    }
  }, [account]);

  const giftsToShow = isLoading
    ? []
    : theGifts.filter((gift: Gift) => {
        const foundGift = gifts.find(
          (item: any) =>
            item.account === account && gift.timestamp === item.timestamp
        );
        return typeof foundGift === "undefined";
      });

  const closeHandler = () => {
    onClose();

    setForceDisabled(true);

    const giftsToAdd = giftsToShow.map((item) => ({
      account,
      timestamp: item.timestamp,
      address: item.address,
    }));

    const allGifts = storage.getValue(GIFT_STORAGE_KEY);
    let resultGifts;

    if (allGifts) {
      const parsedGifts = JSON.parse(allGifts);
      resultGifts = parsedGifts.concat(giftsToAdd);
    } else {
      resultGifts = giftsToAdd;
    }

    storage.addValue(GIFT_STORAGE_KEY, JSON.stringify(resultGifts));
  };

  if (isLoading || giftsToShow.length === 0 || forceDisabled) {
    return null;
  }

  return (
    <Modal visible={isOpen} onCancel={closeHandler} centered>
      <GiftsModal gifts={giftsToShow} />
    </Modal>
  );
};

export default Gifts;
