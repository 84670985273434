export const ERC20TokenABI = [
  "function symbol() public view returns (string)",
  "function decimals() public view returns (uint8)",
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
];

export const PureFiFarmingVerifiableABILegacy = [
  "function getPool(uint16 _index) external view returns (address, uint256, uint64, uint64, uint64, uint256, uint256)",
  "function getPoolLength() external view returns (uint256)",
  "function getContractData() external view returns (uint256, uint256, uint64)",
  "function getUserInfo(uint16 _pid, address _user) external view returns (uint256, uint256, uint256)",
  "function deposit(uint16 _pid, uint256 _amount, uint256[] memory data, bytes memory signature) external payable",
  "function depositTo(uint16 _pid, uint256 _amount, address _beneficiary, uint256[] memory data, bytes memory signature) external payable",
  "function withdraw(uint16 _pid, uint256 _amount) external",
  "function claimReward(uint16 _pid) external",
  "function exit(uint16 _pid) external",
  "function getUserStakedTime(uint16 _pid, address _user) external view returns(uint64)",
  "function getPoolMinStakingTime(uint16 _index) public view returns(uint64)",
  "function rewardToken() public view returns(address)",
  "function getPoolMaxStakingAmount(uint16 _index) public view returns(uint256)",
  "function noRewardClaimsUntil() public view returns(uint64)",
  "function getPoolCommission(uint16 _index) public view returns(address, uint256)",
];

export const PureFiFarmingVerifiableABI = [
  "function getPool(uint16 _index) external view returns (address, uint256, uint64, uint64, uint64, uint256, uint256)",
  "function getPoolLength() external view returns (uint256)",
  "function getContractData() external view returns (uint256, uint256, uint64)",
  "function getUserInfo(uint16 _pid, address _user) external view returns (uint256, uint256, uint256)",
  "function deposit(uint16 _pid, uint256 _amount, bytes calldata _purefidata) external payable",
  "function depositTo(uint16 _pid, uint256 _amount, address _beneficiary, bytes calldata _purefidata) external payable",
  "function withdraw(uint16 _pid, uint256 _amount) external",
  "function claimReward(uint16 _pid) external",
  "function exit(uint16 _pid) external",
  "function getUserStakedTime(uint16 _pid, address _user) external view returns(uint64)",
  "function getPoolMinStakingTime(uint16 _index) public view returns(uint64)",
  "function rewardToken() public view returns(address)",
  "function getPoolMaxStakingAmount(uint16 _index) public view returns(uint256)",
  "function noRewardClaimsUntil() public view returns(uint64)",
  "function getPoolCommission(uint16 _index) public view returns(address, uint256)",
];

export const PureFiDestributorABI = [
  "function getWinInfo(address _user) external view returns(uint64 timestamp, uint256 amount)",
];
