/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuidv4 } from "uuid";

import { Farm, PackType } from "models";

import ProductionScanner from "helpers/ProductionScanner";

import purefiLogo from "assets/purefi-logo.svg";
import purefiSecondLogo from "assets/purefi-second-logo.svg";
import ultraLogo from "assets/ultrasafe.webp";
import idttLogo from "assets/idttlogo.svg";
import lttLogo from "assets/ltt-logo.png";
import safleLogo from "assets/safle-logo.png";
import scaLogo from "assets/sca-logo.webp";
import kiroboLogo from "assets/kirobo-logo.png";
import auroraLogo from "assets/aurora.png";
import auroraSecondLogo from "assets/aurora2.png";

import {
  ETHEREUM_MAINNET_NETWORK,
  BSC_MAINNET_NETWORK,
  POLYGON_MAINNET_NETWORK,
  AURORA_MAINNET_NETWORK,
  RINKEBY_NETWORK,
  BSC_TEST_NETWORK,
  POLYGON_TESTNET_NETWORK,
  AURORA_TESTNET_NETWORK,
} from "./networks";
import { DEFAULT_OPTIONALKYCAML_RULE } from "./rules";

const IS_PRODUCTION = ProductionScanner.check();

const UFI_BINANCE_CONTRACT_ADDRESS =
  process.env.REACT_APP_UFI_BINANCE_CONTRACT_ADDRESS ||
  "0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d";
const ULTRA_BINANCE_CONTRACT_ADDRESS =
  process.env.REACT_APP_ULTRA_BINANCE_CONTRACT_ADDRESS ||
  "0x0b3f42481c228f70756dbfa0309d3ddc2a5e0f6a";
const IDTT_BINANCE_CONTRACT_ADDRESS =
  process.env.REACT_APP_IDTT_BINANCE_CONTRACT_ADDRESS ||
  "0x6fb1e018f107d3352506c23777e4cd62e063584a";
const LTT_BINANCE_CONTRACT_ADDRESS =
  process.env.REACT_APP_LTT_BINANCE_CONTRACT_ADDRESS ||
  "0x1dc84fc11e48ae640d48044f22a603bbe914a612";
const SAFLE_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_SAFLE_POLYGON_CONTRACT_ADDRESS ||
  "0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126";
const SCALESWAP_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_SCALESWAP_POLYGON_CONTRACT_ADDRESS ||
  "0x11a819beb0aa3327e39f52f90d65cc9bca499f33";
const KIROBO_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_KIROBO_POLYGON_CONTRACT_ADDRESS ||
  "0xb382c1cfa622795a534e5bd56fac93d59bac8b0d";
const AURORA_ETHEREUM_CONTRACT_ADDRESS =
  process.env.REACT_APP_AURORA_ETHEREUM_CONTRACT_ADDRESS ||
  "0xAaAAAA20D9E0e2461697782ef11675f668207961";

// array of PRODUCTION farms
// they are always present among farms regardless env
const PRODUCTION_FARMS: Farm[] = [
  // {
  //   id: uuidv4(),
  //   contractAddress: "0xafAb7848AaB0F9EEF9F9e29a83BdBBBdDE02ECe5",
  //   networkId: ETHEREUM_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "UFI",
  //     icon: purefiSecondLogo,
  //     addresses: {
  //       binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-07-28T17:16:30.000Z",
  //       endDate: "2021-09-26T23:49:30.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  {
    id: uuidv4(),
    contractAddress: "0x33f86fDc03387A066c4395677658747c696932Eb",
    networkId: BSC_MAINNET_NETWORK.networkId,
    rewardToken: {
      symbol: "UFI",
      icon: purefiSecondLogo,
    },
    pools: [
      {
        index: 0,
        startDate: "2021-07-28T17:12:44.000Z",
        endDate: "2021-09-29T13:02:02.000Z",
        depositToken: {
          symbol: "UFI",
          icon: purefiLogo,
        },
      },
    ],
  },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x7DFb82932F9c7aa91847b9B350990B7c900C7602",
  //   networkId: BSC_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "ULTRA",
  //     icon: ultraLogo,
  //     addresses: {
  //       binance: ULTRA_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-10-01T18:03:17.000Z",
  //       endDate: "2021-10-29T18:03:08.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0xAc8892AC86bB02F26544F31af06b86fdD2105862",
  //   networkId: BSC_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "ULTRA",
  //     icon: ultraLogo,
  //     addresses: {
  //       binance: ULTRA_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-11-10T19:00:00.000Z",
  //       endDate: "2021-12-10T19:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  {
    id: uuidv4(),
    contractAddress: "0x0e2F752C845Bdb31368d7012CA93f45AF345Ec73",
    networkId: BSC_MAINNET_NETWORK.networkId,
    rewardToken: {
      symbol: "IDTT",
      icon: idttLogo,
      addresses: {
        binance: IDTT_BINANCE_CONTRACT_ADDRESS,
      },
    },
    pools: [
      {
        index: 0,
        startDate: "2021-10-08T12:00:47.000Z",
        endDate: "2021-11-07T13:00:17.000Z",
        depositToken: {
          symbol: "UFI",
          icon: purefiLogo,
          addresses: {
            binance: UFI_BINANCE_CONTRACT_ADDRESS,
          },
        },
      },
    ],
  },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x8a92E706cd359536D7A57dC9CC24054f7B17e021",
  //   networkId: BSC_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "LTT",
  //     icon: lttLogo,
  //     price: 0.2,
  //     addresses: {
  //       binance: LTT_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-08T16:00:00.000Z",
  //       endDate: "2022-01-23T16:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //       depositNoticeMessage:
  //         "20% of the farmed tokens will be unlocked on 23 Jan 2022, and the rest of the tokens will be unlocked linearly in the next 5 months.",
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x5A26315f72efB90eC77a879eF781246B663d5482",
  //   networkId: POLYGON_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "SAFLE",
  //     icon: safleLogo,
  //     addresses: {
  //       polygon: SAFLE_POLYGON_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-29T15:00:00.000Z",
  //       endDate: "2022-01-28T15:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //       depositNoticeMessage:
  //         "To enter the farming you need at least 10 MATIC. This amount is charging to buy additional $UFI for you and will appear in your wallet.",
  //     },
  //     {
  //       index: 1,
  //       startDate: "2021-12-29T15:00:00.000Z",
  //       endDate: "2022-01-28T15:00:00.000Z",
  //       depositToken: {
  //         symbol: "SAFLE",
  //         icon: safleLogo,
  //         addresses: {
  //           polygon: SAFLE_POLYGON_CONTRACT_ADDRESS,
  //         },
  //       },
  //       depositNoticeMessage:
  //         "To enter the farming you need at least 10 MATIC. This amount is charging to buy additional $SAFLE for you and will appear in your wallet.",
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x236e7E724c309fEaEBB1d5a36b33a3b8f1617952",
  //   networkId: POLYGON_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "SCA",
  //     icon: scaLogo,
  //     addresses: {
  //       polygon: SCALESWAP_POLYGON_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-31T00:00:00.000Z",
  //       endDate: "2022-02-01T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "SCA",
  //         icon: scaLogo,
  //         addresses: {
  //           polygon: SCALESWAP_POLYGON_CONTRACT_ADDRESS,
  //         },
  //       },
  //       depositNoticeMessage:
  //         "To enter the farming you need at least 10 MATIC. This amount is charging to buy additional $UFI for you and will appear in your wallet.",
  //       noticeMessage: "SCA tokens withdrawal is locked until 1 April 2022",
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x0000000000000000000000000000000000000000",
  //   networkId: POLYGON_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "KIRO",
  //     icon: kiroboLogo,
  //     addresses: {
  //       polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //     {
  //       index: 1,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "KIRO",
  //         icon: kiroboLogo,
  //         addresses: {
  //           polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x0000000000000000000000000000000000000000",
  //   networkId: ETHEREUM_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "KIRO",
  //     icon: kiroboLogo,
  //     addresses: {
  //       polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //     {
  //       index: 1,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "KIRO",
  //         icon: kiroboLogo,
  //         addresses: {
  //           polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x0000000000000000000000000000000000000000",
  //   networkId: BSC_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "KIRO",
  //     icon: kiroboLogo,
  //     addresses: {
  //       polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //     {
  //       index: 1,
  //       startDate: "2021-12-21T00:00:00.000Z",
  //       endDate: "2022-03-21T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "KIRO",
  //         icon: kiroboLogo,
  //         addresses: {
  //           polygon: KIROBO_POLYGON_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x6b54F342DC6f51643773fb77231d4d34f35b7D84",
  //   networkId: BSC_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "UFI",
  //     icon: purefiSecondLogo,
  //     addresses: {
  //       binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2022-09-22T00:00:00.000Z",
  //       endDate: "2022-10-22T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  //   verifiableDesc: {
  //     depositRuleId: DEFAULT_OPTIONALKYCAML_RULE,
  //     projectId: "9c035409-7e3e-43ad-9644-7eb63d99d01e",
  //     chainId: BSC_MAINNET_NETWORK.networkId,
  //   },
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x101e8e1174e230Bc9529B827B73C4ABE63AA400F",
  //   networkId: BSC_TEST_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "UFI",
  //     icon: purefiSecondLogo,
  //     price: 0.0082,
  //     addresses: {
  //       binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2022-10-19T00:00:00.000Z",
  //       endDate: "2022-12-31T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "UFI",
  //         price: 0.0082,
  //         icon: purefiLogo,
  //         addresses: {
  //           binance: UFI_BINANCE_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  //   verifiableDesc: {
  //     v: PackType.one,
  //     depositRuleId: DEFAULT_OPTIONALKYCAML_RULE,
  //     projectId: "9c035409-7e3e-43ad-9644-7eb63d99d01e",
  //     chainId: BSC_TEST_NETWORK.networkId,
  //     actualTokenAddress: "0x416E6E78208a3066B6f28D22d033c8d25625d266",
  //   },
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x3699A584c69ECe4B73EaDe7a99d4902Abd585a84",
  //   networkId: AURORA_TESTNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "AURORA",
  //     icon: auroraSecondLogo,
  //     addresses: {
  //       ethereum: AURORA_ETHEREUM_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2022-11-03T00:00:00.000Z",
  //       endDate: "2022-12-31T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "AURORA",
  //         icon: auroraLogo,
  //         addresses: {
  //           ethereum: AURORA_ETHEREUM_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  //   verifiableDesc: {
  //     v: PackType.zero,
  //     depositRuleId: DEFAULT_OPTIONALKYCAML_RULE,
  //     projectId: "9c035409-7e3e-43ad-9644-7eb63d99d01e",
  //     chainId: AURORA_TESTNET_NETWORK.networkId,
  //     actualTokenAddress: "0xf2e7dF3E35de8A9f7A0581487759eE317d105B1E",
  //   },
  // },
  // {
  //   id: uuidv4(),
  //   contractAddress: "0x9698C74E98d76B8a73a8E453A5BcB44B05260549",
  //   networkId: AURORA_MAINNET_NETWORK.networkId,
  //   rewardToken: {
  //     symbol: "AURORA",
  //     icon: auroraSecondLogo,
  //     addresses: {
  //       ethereum: AURORA_ETHEREUM_CONTRACT_ADDRESS,
  //     },
  //   },
  //   pools: [
  //     {
  //       index: 0,
  //       startDate: "2022-11-07T00:00:00.000Z",
  //       endDate: "2022-12-31T00:00:00.000Z",
  //       depositToken: {
  //         symbol: "AURORA",
  //         icon: auroraLogo,
  //         addresses: {
  //           ethereum: AURORA_ETHEREUM_CONTRACT_ADDRESS,
  //         },
  //       },
  //     },
  //   ],
  //   verifiableDesc: {
  //     v: PackType.zero,
  //     depositRuleId: DEFAULT_OPTIONALKYCAML_RULE,
  //     projectId: "9c035409-7e3e-43ad-9644-7eb63d99d01e",
  //     chainId: AURORA_MAINNET_NETWORK.networkId,
  //     actualTokenAddress: "0x1b4aFFd88C90B5d2B3C5a9261deF3c8E55f7616B",
  //   },
  // },
  {
    id: uuidv4(),
    contractAddress: "0x55376751e9b92cb9384BA671FE88aF5B76B4C840",
    networkId: BSC_TEST_NETWORK.networkId,
    rewardToken: {
      symbol: "UFI",
      icon: purefiSecondLogo,
      price: 0.0082,
      addresses: {
        binance: UFI_BINANCE_CONTRACT_ADDRESS,
      },
    },
    pools: [
      {
        index: 0,
        startDate: "2022-10-19T00:00:00.000Z",
        endDate: "2022-12-31T00:00:00.000Z",
        depositToken: {
          symbol: "UFI",
          price: 0.0082,
          icon: purefiLogo,
          addresses: {
            binance: UFI_BINANCE_CONTRACT_ADDRESS,
          },
        },
      },
    ],
    verifiableDesc: {
      v: PackType.two,
      depositRuleId: DEFAULT_OPTIONALKYCAML_RULE,
      projectId: "9c035409-7e3e-43ad-9644-7eb63d99d01e",
      chainId: BSC_TEST_NETWORK.networkId,
      actualTokenAddress: "0x416E6E78208a3066B6f28D22d033c8d25625d266",
    },
  },
];

// array of NON-PRODUCTION farms
// they won't appear in production if REACT_APP_IS_PRODUCTION === 'true'
// for details see how github action workflows are configred
const OTHER_FARMS: Farm[] = [];

const FARMS: Farm[] = PRODUCTION_FARMS;

if (!IS_PRODUCTION && OTHER_FARMS.length > 0) {
  FARMS.push(...OTHER_FARMS);
}

export default FARMS;
