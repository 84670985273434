const DEFAULT_AML_RULE = "431050";
const DEFAULT_KYC_RULE = "777";
const DEFAULT_KYCAML_RULE = "731090";
const DEFAULT_OPTIONALKYCAML_RULE = "631050090";

export {
  DEFAULT_AML_RULE,
  DEFAULT_KYC_RULE,
  DEFAULT_KYCAML_RULE,
  DEFAULT_OPTIONALKYCAML_RULE,
};
