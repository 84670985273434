/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers, BigNumber } from "ethers";
import { PureFI, VerifyRuleResponse } from "@purefi/verifier-sdk";
import detectEthereumProvider from "@metamask/detect-provider";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumRpcError } from "eth-rpc-errors";

import {
  Farm,
  Network,
  Pool,
  Token,
  PoolState,
  Gift,
  PackType,
  VerifiableDesc,
  GetSnapsResponse,
  Snap,
} from "models";
import {
  DEFAULT_NETWORK,
  BSC_MAINNET_NETWORK,
  POLYGON_MAINNET_NETWORK,
  BSC_TEST_NETWORK,
  POLYGON_TESTNET_NETWORK,
  NETWORKS,
  defaultSnapOrigin,
  requiredSnapVersion,
} from "config";

import { priceService } from "api";
import { formatUTC } from "helpers/date";
import VerifiableHelper from "helpers/VerifiableHelper";

import {
  ERC20TokenABI,
  PureFiDestributorABI,
  PureFiFarmingVerifiableABILegacy,
  PureFiFarmingVerifiableABI,
} from "./abi";

const UINT256_MAX_INT = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

const DEFAULT_CURRENCY = "usd";

type PoolDesc = {
  index: number;
  rewardToken: Token;
  depositToken: Token;
  startDate: string;
  endDate: string;
  depositNoticeMessage?: string;
  noticeMessage?: string;
};

interface OriginalError {
  code: number;
  message: string;
  stack?: string;
}

class Ethereum {
  private metaMaskWallet = (window as any).ethereum;

  private provider =
    this.metaMaskWallet &&
    new ethers.providers.Web3Provider(this.metaMaskWallet);

  private account: string = "";

  private network: Network = DEFAULT_NETWORK;

  init = (
    walletProvider: null | WalletConnectProvider,
    accountsChanged: (account: string) => void,
    networkChanged: (chainId: number) => void
  ): void => {
    const newProvider = walletProvider || this.metaMaskWallet;
    this.provider = new ethers.providers.Web3Provider(newProvider);

    newProvider.on("accountsChanged", ([account]: string[]) => {
      this.account = account || "";
      accountsChanged(this.account);
    });

    const provider = new ethers.providers.Web3Provider(newProvider, "any");
    provider.on("network", ({ chainId }) => {
      // Set current network SC address
      this.network = NETWORKS[chainId];

      // Update provider when network changed
      this.provider = new ethers.providers.Web3Provider(newProvider);

      networkChanged(chainId);
    });
  };

  getMetaMaskWalletAccount = async (request: boolean): Promise<string> => {
    const method = request ? "eth_requestAccounts" : "eth_accounts";
    const [account] = await this.metaMaskWallet.request({
      method,
    });
    return account || "";
  };

  isMetaMaskProviderExist = async (): Promise<boolean> => {
    const provider = await detectEthereumProvider();
    return !!provider;
  };

  detectFlask = async () => {
    try {
      const clientVersion = await this.provider.provider?.request({
        method: "web3_clientVersion",
      });

      const isFlaskDetected = (clientVersion as string[])?.includes("flask");

      return Boolean(isFlaskDetected);
    } catch {
      return false;
    }
  };

  installSnap = async (
    snapId: string = defaultSnapOrigin,
    params: Record<"version" | string, unknown> = {}
  ) => {
    const theParams = [
      {
        wallet_snap: {
          [snapId]: {
            version: requiredSnapVersion,
            ...params,
          },
        },
      },
    ];

    const result = await (window as any).ethereum.request({
      method: "wallet_enable",
      params: theParams,
    });
  };

  getSnap = async (
    version: string = requiredSnapVersion
  ): Promise<Snap | null> => {
    try {
      const snaps = (await (window as any).ethereum.request({
        method: "wallet_getSnaps",
      })) as unknown as GetSnapsResponse;

      if (!snaps || Object.keys(snaps).length === 0) {
        return null;
      }

      const theSnap = Object.values(snaps).find(
        (snap) =>
          snap.id === defaultSnapOrigin &&
          (!version || snap.version === version.slice(1))
      );

      return theSnap || null;
    } catch (e) {
      console.log("Failed to obtain installed snap", e);
      return null;
    }
  };

  invokeSnap = async () => {
    const result = await (window as any).ethereum.request({
      method: "wallet_invokeSnap",
      params: [
        defaultSnapOrigin,
        {
          method: "hello",
        },
      ],
    });

    return result;
  };

  setWalletAccount = (account: string): void => {
    this.account = account;
  };

  setNetwork = async (newNetwork: Network): Promise<boolean> => {
    this.network = newNetwork;

    if (this.metaMaskWallet) {
      const chainId = `0x${newNetwork.networkId.toString(16)}`;

      try {
        await this.metaMaskWallet.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
        return true;
      } catch (error) {
        const rpcError = error as EthereumRpcError<OriginalError>;

        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (rpcError.code === 4902) {
          try {
            await this.metaMaskWallet.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId,
                  chainName: newNetwork.alias,
                  nativeCurrency: newNetwork.nativeCurrency,
                  rpcUrls: [newNetwork.rpcUrl],
                  blockExplorerUrls: [newNetwork.blockExplorerUrl],
                },
              ],
            });
            // cb();
            return true;
          } catch (anotherError) {
            const anotherRpcError =
              anotherError as EthereumRpcError<OriginalError>;
            console.log(anotherRpcError);
            return false;
          }
        }
        console.log(rpcError);
        return false;
      }
    } else {
      return false;
    }
  };

  getBalance = async (): Promise<string> => {
    const balance = await this.provider.getBalance(this.account);
    return ethers.utils.formatEther(balance);
  };

  getNetwork = async (): Promise<number> => {
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  };

  parseDecimals = async (
    value: number | string,
    address: string
  ): Promise<BigNumber> => {
    const contract = new ethers.Contract(address, ERC20TokenABI, this.provider);
    const decimals = await this.getDecimals(contract);
    return parseFixed(value.toString(), decimals);
  };

  getTokenPrice = async (token: Token): Promise<number> => {
    // check for preconfigured value

    if (token?.price) {
      return Promise.resolve(token.price);
    }
    // an api call otherwise
    const price = await priceService.fetchTokenPrice(
      token?.addresses,
      DEFAULT_CURRENCY
    );
    return price;
  };

  getDecimals = async (contract: ethers.Contract): Promise<number> => {
    try {
      const decimals = await contract.decimals();
      return decimals;
    } catch {
      return 18;
    }
  };

  getPoolState = async (
    startBlock: number,
    endBlock: number,
    endDate: string
  ): Promise<PoolState> => {
    if (new Date(endDate) < new Date()) {
      return PoolState.ended;
    }
    const currentBlock = await this.provider.getBlockNumber();
    if (currentBlock < startBlock) {
      return PoolState.upcoming;
    }
    if (currentBlock > endBlock) {
      return PoolState.ended;
    }
    return PoolState.active;
  };

  getTokenAllowance = async (
    tokenContract: ethers.Contract,
    farmContractAddress: string
  ): Promise<boolean> => {
    const allowance: BigNumber = await tokenContract.allowance(
      this.account,
      farmContractAddress
    );
    return !allowance.isZero();
  };

  getWithdrawTime = async (
    stake: string,
    farmContract: ethers.Contract,
    poolIndex: number
  ): Promise<number> => {
    try {
      if (Number(stake) <= 0) {
        return Date.now();
      }

      const userStakedTime: BigNumber = await farmContract.getUserStakedTime(
        poolIndex,
        this.account
      );
      const minStakingTime: BigNumber =
        await farmContract.getPoolMinStakingTime(poolIndex);
      return (
        (userStakedTime.toNumber() + minStakingTime.toNumber()) * 1000 ||
        Date.now()
      );
    } catch (e) {
      console.log("WITHDRAW_TIME_ERROR", e);
      return Date.now();
    }
  };

  getClaimTime = async (farmContract: ethers.Contract): Promise<number> => {
    try {
      const claimTime: BigNumber =
        await farmContract.noRewardClaimsUntil.call();
      return claimTime.toNumber() * 1000;
    } catch (e) {
      console.log("CLAIM_TIME_ERROR", e);
      return Date.now();
    }
  };

  calcPoolDates = (
    poolState: PoolState,
    poolStartDate: string,
    poolEndDate: string
  ): Array<string> => {
    let startDate = formatUTC(poolStartDate);
    let endDate = formatUTC(poolEndDate);

    if (poolState === PoolState.ended) {
      startDate = "";
      endDate = "Pool has already ended";
    }

    return [startDate, endDate];
  };

  calcFarmingApy = (
    depositTokenAddress: string,
    rewardTokenAddress: string,
    depositTokenPrice: number,
    rewardTokenPrice: number,
    depositTokenDecimals: number,
    rewardTokenDecimals: number,
    totalAllocationPoint: BigNumber,
    allocationPoint: BigNumber,
    totalDeposited: BigNumber,
    tokensFarmedPerBlock: BigNumber
  ): number => {
    if (totalDeposited.isZero()) {
      return 1000;
    }

    if (totalAllocationPoint.isZero()) {
      return 0;
    }

    const { amountOfBlocksPerYear } = this.network;

    // if deposit and reward tokens are the same
    if (rewardTokenAddress === depositTokenAddress) {
      return BigNumber.from(100)
        .mul(BigNumber.from(amountOfBlocksPerYear))
        .mul(allocationPoint)
        .div(totalAllocationPoint)
        .mul(tokensFarmedPerBlock)
        .div(totalDeposited)
        .toNumber();
    }

    const depositTokenPriceBN = BigNumber.from(
      Math.round(depositTokenPrice * 10 ** 12)
    );
    const rewardTokenPriceBN = BigNumber.from(
      Math.round(rewardTokenPrice * 10 ** 12)
    );

    return BigNumber.from(100)
      .mul(BigNumber.from(amountOfBlocksPerYear))
      .mul(allocationPoint)
      .div(totalAllocationPoint)
      .mul(
        tokensFarmedPerBlock
          .mul(rewardTokenPriceBN)
          .div(BigNumber.from(10).pow(rewardTokenDecimals))
      )
      .div(
        totalDeposited
          .mul(depositTokenPriceBN)
          .div(BigNumber.from(10).pow(depositTokenDecimals))
      )
      .toNumber();
  };

  calcEarnings = (
    amount: BigNumber,
    totalAllocationPoint: BigNumber,
    allocationPoint: BigNumber,
    totalDeposited: BigNumber,
    tokensFarmedPerBlock: BigNumber,
    rewardTokenDecimals: number
  ): Array<number> => {
    if (totalAllocationPoint.isZero() || totalDeposited.isZero()) {
      return [0, 0, 0];
    }

    const { amountOfBlocksPerYear } = this.network;

    const perBlockEarnings = Number(
      formatFixed(
        tokensFarmedPerBlock
          .mul(allocationPoint)
          .div(totalAllocationPoint)
          .mul(amount)
          .div(totalDeposited),
        rewardTokenDecimals
      )
    );

    const dailyEarnings = (perBlockEarnings * amountOfBlocksPerYear) / 365.25;
    const monthlyEarnings = (perBlockEarnings * amountOfBlocksPerYear) / 12;
    const yearlyEarnings = perBlockEarnings * amountOfBlocksPerYear;

    return [dailyEarnings, monthlyEarnings, yearlyEarnings];
  };

  calcAmountToDeposit = async (
    farmContract: ethers.Contract,
    poolIndex: number,
    amount: BigNumber,
    depositTokenDecimals: number
  ): Promise<string> => {
    try {
      const maxStakingAmount = await farmContract.getPoolMaxStakingAmount(
        poolIndex
      );
      const allowedAmountToDeposit = formatFixed(
        maxStakingAmount.sub(amount),
        depositTokenDecimals
      );
      if (Number(allowedAmountToDeposit) < 0) {
        return "0";
      }
      return allowedAmountToDeposit;
    } catch (e) {
      return Number.POSITIVE_INFINITY.toString();
    }
  };

  getFee = async (
    farmContract: ethers.Contract,
    poolIndex: number
  ): Promise<string> => {
    try {
      const data = await farmContract.getPoolCommission(poolIndex);
      const fee = data[1].toString();
      return fee;
    } catch (e) {
      return "";
    }
  };

  getGiftsByFarm = async (farm: Farm): Promise<Gift | null> => {
    try {
      const distributor = farm?.verifiableDesc?.distributor;

      if (typeof distributor === "undefined") {
        return null;
      }

      const signer = this.provider.getSigner();
      const distributorContract = new ethers.Contract(
        distributor.address,
        PureFiDestributorABI,
        signer
      );

      const gift = await this.getWinInfo(
        distributorContract,
        distributor.token.symbol,
        distributor.token.decimals
      );

      return gift;
    } catch (e) {
      return null;
    }
  };

  // get win info
  getWinInfo = async (
    distributorContract: ethers.Contract,
    distributorSymbol: string,
    distributorTokenDecimals: number
  ): Promise<Gift | null> => {
    try {
      const winInfo = await distributorContract.getWinInfo(this.account);

      if (winInfo.amount.isZero() || winInfo.timestamp.isZero()) {
        return null;
      }

      const gift: Gift = {
        address: distributorContract.address,
        amount: formatFixed(winInfo.amount, distributorTokenDecimals),
        timestamp: winInfo.timestamp.toString(),
        symbol: distributorSymbol,
      };
      return gift;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  getPoolData = async (
    farmContract: ethers.Contract,
    signer: any,
    poolDesc: PoolDesc
  ): Promise<Pool | null> => {
    try {
      // get the pool
      const pool = await farmContract.getPool(poolDesc.index);

      // get deposit token contract
      const depositTokenAddress: string = pool[0];
      const depositTokenContract = new ethers.Contract(
        depositTokenAddress,
        ERC20TokenABI,
        signer
      );

      // get reward token contract
      const rewardTokenAddress: string = await farmContract.rewardToken();
      const rewardTokenContract = new ethers.Contract(
        rewardTokenAddress,
        ERC20TokenABI,
        signer
      );

      // get start and end blocks
      const startBlock: number = pool[2].toNumber();
      const endBlock: number = pool[3].toNumber();

      // get pool state
      const state = await this.getPoolState(
        startBlock,
        endBlock,
        poolDesc.endDate
      );

      // get token prices
      const depositTokenPrice = await this.getTokenPrice(poolDesc.depositToken);
      const rewardTokenPrice = await this.getTokenPrice(poolDesc.rewardToken);

      // get deposit token decimals
      const depositTokenDecimals = await this.getDecimals(depositTokenContract);

      // get reward token decimals
      const rewardTokenDecimals = await this.getDecimals(rewardTokenContract);

      // get contract related data
      const contractData = await farmContract.getContractData();
      const tokensFarmedPerBlock: BigNumber = contractData[0];
      const totalAllocationPoint: BigNumber = contractData[1];

      // get the rest pool-related data
      const allocationPoint: BigNumber = pool[1];
      const totalDeposited: BigNumber = pool[6];

      // calc farming apy
      const farmingAPY = this.calcFarmingApy(
        depositTokenAddress,
        rewardTokenAddress,
        depositTokenPrice,
        rewardTokenPrice,
        depositTokenDecimals,
        rewardTokenDecimals,
        totalAllocationPoint,
        allocationPoint,
        totalDeposited,
        tokensFarmedPerBlock
      );

      // get user info
      const userInfo = await farmContract.getUserInfo(
        poolDesc.index,
        this.account
      );

      const amount: BigNumber = userInfo[0];
      const totalRewarded: BigNumber = userInfo[1];
      const pendingReward: BigNumber = userInfo[2];

      // calc stake and reward
      const stake = formatFixed(amount, depositTokenDecimals);
      const reward = formatFixed(
        totalRewarded.add(pendingReward),
        rewardTokenDecimals
      );

      // get user balance
      const balanceOfAccount: BigNumber = await depositTokenContract.balanceOf(
        this.account
      );
      const balance = formatFixed(balanceOfAccount, depositTokenDecimals);

      // calc earnings
      const [dailyEarnings, monthlyEarnings, yearlyEarnings] =
        this.calcEarnings(
          amount,
          totalAllocationPoint,
          allocationPoint,
          totalDeposited,
          tokensFarmedPerBlock,
          rewardTokenDecimals
        );

      // get deposit token allowance
      const depositTokenAllowance = await this.getTokenAllowance(
        depositTokenContract,
        farmContract.address
      );

      // get withdraw time
      const withdrawTime = await this.getWithdrawTime(
        stake,
        farmContract,
        poolDesc.index
      );

      // get claim time
      const claimTime = await this.getClaimTime(farmContract);

      // calc start and end dates
      const [startDate, endDate] = this.calcPoolDates(
        state,
        poolDesc.startDate,
        poolDesc.endDate
      );

      // calc allowed amount to deposit
      const allowedAmountToDeposit = await this.calcAmountToDeposit(
        farmContract,
        poolDesc.index,
        amount,
        depositTokenDecimals
      );

      const poolData: Pool = {
        totalStaked: formatFixed(totalDeposited, depositTokenDecimals),
        farmingAPY,
        stake,
        reward,
        pendingReward: formatFixed(pendingReward, rewardTokenDecimals),
        state,
        balance,
        dailyEarnings,
        monthlyEarnings,
        yearlyEarnings,
        liquidityTokenAddress: depositTokenAddress,
        contractAddress: farmContract.address,
        poolIndex: poolDesc.index,
        allowance: depositTokenAllowance,
        startBlock,
        endBlock,
        withdrawTime,
        claimTime,
        depositTokenPrice,
        rewardTokenPrice,
        allowedAmountToDeposit,
        depositToken: {
          symbol: poolDesc.depositToken.symbol,
          icon: poolDesc.depositToken.icon,
        },
        rewardToken: {
          symbol: poolDesc.rewardToken.symbol,
          icon: poolDesc.rewardToken.icon,
        },
        startDate,
        endDate,
        depositNoticeMessage: poolDesc?.depositNoticeMessage,
        noticeMessage: poolDesc?.noticeMessage,
      };
      return poolData;
    } catch (e) {
      return null;
    }
  };

  getPoolsByFarm = async (farm: Farm): Promise<Pool[]> => {
    try {
      const { contractAddress } = farm;
      const signer = this.provider.getSigner();

      const verifiableDesc = VerifiableHelper.getDescription(contractAddress);

      const ABI =
        verifiableDesc?.v === PackType.zero
          ? PureFiFarmingVerifiableABILegacy
          : PureFiFarmingVerifiableABI;

      const farmContract = new ethers.Contract(contractAddress, ABI, signer);

      const poolLength = (await farmContract.getPoolLength()).toNumber();

      const indexes = Array.from(Array(poolLength).keys());

      const poolsData = await Promise.all(
        indexes.map((poolIndex) => {
          const poolConfig = farm.pools.find(
            (item) => item.index === poolIndex
          );
          if (poolConfig) {
            const poolDesc: PoolDesc = {
              index: poolIndex,
              rewardToken: farm.rewardToken,
              depositToken: poolConfig.depositToken,
              startDate: poolConfig.startDate,
              endDate: poolConfig.endDate,
              depositNoticeMessage: poolConfig?.depositNoticeMessage,
              noticeMessage: poolConfig?.noticeMessage,
            };
            return this.getPoolData(farmContract, signer, poolDesc);
          }
          console.log("Unconfigured pool found, index = ", poolIndex);
          return Promise.resolve(null);
        })
      );
      return poolsData
        .flat()
        .filter((poolData) => poolData)
        .map((poolData) => poolData as Pool);
    } catch (e) {
      return [];
    }
  };

  verifyRuleHelper = async (
    signer: any,
    data: Record<string, any>,
    callback: () => void,
    projectId?: string
  ): Promise<VerifyRuleResponse> => {
    const message = JSON.stringify(data);

    const signature = await signer.signMessage(message);

    callback();

    const payload = {
      message,
      signature,
    };

    const response = await PureFI.verifyRule(payload, projectId);
    return response;
  };

  approve = async (
    liquidityTokenAddress: string,
    contractAddress: string
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      liquidityTokenAddress,
      ERC20TokenABI,
      signer
    );

    const approved: TransactionResponse = await contract.approve(
      contractAddress,
      UINT256_MAX_INT
    );
    return approved;
  };

  verifyRule = async (
    value: number | string,
    liquidityTokenAddress: string,
    verifiableDesc: VerifiableDesc,
    callback: () => void
  ): Promise<VerifyRuleResponse> => {
    const signer = this.provider.getSigner();
    const amount = await this.parseDecimals(value, liquidityTokenAddress);

    const { v } = verifiableDesc;

    let data;

    if (v === PackType.zero) {
      data = {
        address: this.account,
        ruleId: verifiableDesc.depositRuleId,
        chainId: Number(this.network.networkId),
      };
    } else if (v === PackType.one) {
      data = {
        sender: this.account,
        ruleId: verifiableDesc.depositRuleId,
        chainId: Number(this.network.networkId),
      };
    } else if (v === PackType.two) {
      data = {
        sender: this.account,
        ruleId: verifiableDesc.depositRuleId,
        chainId: Number(this.network.networkId),
        receiver: this.account,
        token: verifiableDesc.actualTokenAddress,
        amount: amount.toHexString(),
      };
    } else if (v === PackType.three) {
      throw new Error("Not implemented");
    } else {
      throw new Error("Bad farm configuration");
    }

    const response: VerifyRuleResponse = await this.verifyRuleHelper(
      signer,
      data,
      callback,
      verifiableDesc.projectId
    );

    return response;
  };

  deposit = async (
    value: number | string,
    liquidityTokenAddress: string,
    contractAddress: string,
    poolIndex: number,
    ruleResponse: VerifyRuleResponse | undefined,
    verifiableDesc: VerifiableDesc | undefined
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const amount = await this.parseDecimals(value, liquidityTokenAddress);

    if (typeof verifiableDesc === "undefined") {
      const contract = new ethers.Contract(
        contractAddress,
        PureFiFarmingVerifiableABILegacy,
        signer
      );

      const fee = await this.getFee(contract, poolIndex);

      const params: any[] = [poolIndex, amount];
      if (fee) {
        params.push({ value: fee });
      }

      const createdDeposit: TransactionResponse = await contract.deposit(
        ...params
      );
      return createdDeposit;
    }

    const { v } = verifiableDesc;

    const subParams: any[] = [];

    if (typeof ruleResponse === "string") {
      subParams.push(ruleResponse);
    } else {
      subParams.push(ruleResponse?.data, ruleResponse?.signature);
    }

    const params: any[] = [poolIndex, amount, ...subParams];

    const contract = new ethers.Contract(
      contractAddress,
      v === PackType.zero
        ? PureFiFarmingVerifiableABILegacy
        : PureFiFarmingVerifiableABI,
      signer
    );

    const createdDeposit: TransactionResponse = await contract.deposit(
      ...params
    );
    return createdDeposit;
  };

  withdraw = async (
    value: number | string,
    liquidityTokenAddress: string,
    contractAddress: string,
    poolIndex: number
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();
    const amount = await this.parseDecimals(value, liquidityTokenAddress);

    const contract = new ethers.Contract(
      contractAddress,
      PureFiFarmingVerifiableABI,
      signer
    );

    const createdWithdraw: TransactionResponse = await contract.withdraw(
      poolIndex,
      amount
    );
    return createdWithdraw;
  };

  claim = async (
    contractAddress: string,
    poolIndex: number
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();

    const contract = new ethers.Contract(
      contractAddress,
      PureFiFarmingVerifiableABI,
      signer
    );

    const createdClaim: TransactionResponse = await contract.claimReward(
      poolIndex
    );
    return createdClaim;
  };

  exit = async (
    contractAddress: string,
    poolIndex: number
  ): Promise<TransactionResponse> => {
    const signer = this.provider.getSigner();

    const contract = new ethers.Contract(
      contractAddress,
      PureFiFarmingVerifiableABI,
      signer
    );

    const createdExit = await contract.exit(poolIndex);
    return createdExit;
  };

  public getExplorerLink = (hash: string): string => {
    const { blockExplorerUrl } = this.network;
    const explorerLink = `${blockExplorerUrl}/tx/${hash}`;
    return explorerLink;
  };
}

const ethereum = new Ethereum();

export default ethereum;
