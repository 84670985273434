import NumberFormat, { NumberFormatValues } from "react-number-format";

import { convertToPrice } from "helpers/formatting";
import { NumericText, PoolAvatar, Button } from "components";
import { Token } from "models";

import styles from "./NumericTextField.module.scss";

type NumericTextFieldProps = {
  value: number | string | null;
  onChange: (value: string) => void;
  maxValue: string;
  price: number;
  depositToken: Token;
  rewardToken: Token;
  readonly?: boolean;
};

const NumericTextField = ({
  value,
  onChange,
  maxValue,
  price,
  depositToken,
  rewardToken,
  readonly = false,
}: NumericTextFieldProps) => {
  const onValueChange = (values: NumberFormatValues) => {
    onChange(values.value);
  };

  const handleMaxClick = () => {
    if (!readonly) {
      onChange(maxValue);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tokenWrapper}>
        <p className={styles.poolToken}>Pool Token</p>

        <div className={styles.avatarWrapper}>
          <div style={{ display: "flex" }}>
            <PoolAvatar
              size={40}
              depositIcon={depositToken.icon}
              outcomeIcon={rewardToken.icon}
            />
          </div>
          <span className={styles.poolAbbr}>
            {depositToken.symbol} - {rewardToken.symbol}
          </span>
        </div>
      </div>

      <div className={styles.inputWrapper}>
        <span className={styles.price}>
          <NumericText value={convertToPrice(value, price)} prefix="≈ $" />
        </span>

        <Button onClick={handleMaxClick} className={styles.maxButton}>
          MAX
        </Button>

        <NumberFormat
          value={value}
          onValueChange={onValueChange}
          placeholder="0"
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          className={styles.input}
          autoFocus
          readOnly={readonly}
        />
      </div>
    </div>
  );
};

export default NumericTextField;
