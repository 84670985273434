export enum WalletStatus {
  connecting = "connecting",
  connected = "connected",
  disconnected = "disconnected",
}

export enum WalletType {
  metamask = "metamask",
  flask = "flask",
  walletConnect = "walletConnect",
  none = "",
}

export enum PoolState {
  active = "active",
  upcoming = "upcoming",
  ended = "ended",
}

export enum PackType {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
}
