import { useQueries } from "react-query";
import { Farm, Gift } from "models";
import ethereum from "utils/ethereum";

const useGifts = (farms: Farm[]) => {
  const results = useQueries(
    farms.map((farm) => ({
      queryKey: ["gifts", farm.id],
      queryFn: () => ethereum.getGiftsByFarm(farm),
    }))
  );

  const isLoading = results.some((item) => item.isLoading);

  return {
    isLoading,
    gifts: isLoading
      ? []
      : results
          .filter((result) => result?.data)
          .map((result) => result.data as Gift),
  };
};

export default useGifts;
