/* eslint-disable @typescript-eslint/no-unused-vars */
import queryString, { StringifiableRecord } from "query-string";
import { Token } from "models";

export default class PriceService {
  private static instance: PriceService;

  private baseUrl: string;

  private constructor(config: Record<string, string>) {
    this.baseUrl = config.baseUrl;
  }

  public static getInstance(config: Record<string, string>): PriceService {
    if (!this.instance) {
      this.instance = new PriceService(config);
    }
    return this.instance;
  }

  private get = async (
    endpoint: string,
    query: StringifiableRecord,
    headers: HeadersInit | undefined
  ) => {
    const sanitizedBaseUrl = this.baseUrl.endsWith("/")
      ? this.baseUrl.slice(0, -1)
      : this.baseUrl;
    const sanitizedEnpoint = endpoint.startsWith("/")
      ? endpoint.slice(1)
      : endpoint;
    const url = `${sanitizedBaseUrl}/${sanitizedEnpoint}`;

    const response = await fetch(queryString.stringifyUrl({ url, query }), {
      headers,
    });
    const data = await response.json();
    return data;
  };

  private fetchTokenPriceByNetwork = async (
    contractAddress: string,
    networkName: string,
    currency: string
  ): Promise<number> => {
    try {
      const data = await this.get(
        `/api/v3/simple/token_price/${networkName}`,
        {
          contract_addresses: contractAddress,
          vs_currencies: currency,
        },
        {
          "Cache-Control": "public, max-age=1000",
          "Access-Control-Allow-Origin": this.baseUrl,
        }
      );
      return Number(data?.[contractAddress.toLowerCase()]?.[currency] || 0);
    } catch (e) {
      console.log("TOKEN_PRICE_ERROR", e);
      return 0;
    }
  };

  public fetchTokenPrice = async (
    tokenAddresses: Token["addresses"],
    currency: string
  ): Promise<number> => {
    let networkName;
    let contractAddress;

    if (tokenAddresses) {
      if ("binance" in tokenAddresses) {
        networkName = "binance-smart-chain";
        contractAddress = tokenAddresses.binance;
      } else if ("polygon" in tokenAddresses) {
        networkName = "polygon-pos";
        contractAddress = tokenAddresses.polygon;
      } else if ("ethereum" in tokenAddresses) {
        networkName = "ethereum";
        contractAddress = tokenAddresses.ethereum;
      }
    }

    if (!contractAddress || !networkName) {
      return 0;
    }

    const price = await this.fetchTokenPriceByNetwork(
      contractAddress,
      networkName,
      currency
    );
    return price;
  };
}
