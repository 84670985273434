import { Row, Col } from "antd";

import { Button } from "components";

import { ReactComponent as GoIcon } from "assets/action-go.svg";

import styles from "./EligibleModal.module.scss";

type EligibleModalProps = {
  title: string;
  text: string;
  link: string;
};

const EligibleModal = ({ title, text, link }: EligibleModalProps) => (
  <div className={styles.modal}>
    <h4 className={styles.title}>{title}</h4>

    <Row gutter={[0, 10]}>
      <Col span={24}>
        <div className={styles.description}>{text}</div>
      </Col>
      <Col span={24}>
        <div className={styles.wrapper}>
          <Button
            className={styles.link}
            href={link}
            target="_blank"
            rel="noopener norefferer"
          >
            <span style={{ marginRight: 10 }}>Proceed to KYC Dashboard</span>
            <GoIcon height="14px" />
          </Button>
        </div>
      </Col>
    </Row>
  </div>
);

export default EligibleModal;
