import { useQueries } from "react-query";

import { Farm, Pool } from "models";
import ethereum from "utils/ethereum";

const usePools = (farms: Farm[]) => {
  const results = useQueries(
    farms.map((farm) => ({
      queryKey: ["pools", farm.id],
      queryFn: () => ethereum.getPoolsByFarm(farm),
    }))
  );

  const isLoading = results.some((item) => item.isLoading);
  const isFetching = results.some((item) => item.isFetching);

  const pools = isLoading
    ? []
    : results
        .filter((result) => result?.data)
        .map((result) => result.data as Pool[])
        .flat()
        .sort((a, b) => b.endBlock - a.endBlock);
  return { isLoading, isFetching, pools };
};

export default usePools;
