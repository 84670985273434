import { FC } from "react";
import { Row, Col } from "antd";
import { Loader, Button } from "components";
import styles from "./LoadingModal.module.scss";

interface LoadingModalProps {
  closeHandler: () => void;
  title: string;
  text: string;
}

const LoadingModal: FC<LoadingModalProps> = (props) => {
  const { closeHandler, title, text } = props;
  return (
    <div className={styles.modal}>
      <h4 className={styles.title}>{title}</h4>
      <Loader />
      <p className={styles.description}>{text}</p>

      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Button onClick={closeHandler} className={styles.button}>
            Close
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LoadingModal;
