import queryString, { StringifiableRecord } from "query-string";

export default class DateService {
  private static instance: DateService;

  private baseUrl: string;

  private apiKey: string;

  private constructor(config: Record<string, string>) {
    this.baseUrl = config.baseUrl;
    this.apiKey = config.apiKey;
  }

  public static getInstance(config: Record<string, string>): DateService {
    if (!this.instance) {
      this.instance = new DateService(config);
    }
    return this.instance;
  }

  private get = async (endpoint: string, query: StringifiableRecord) => {
    const sanitizedBaseUrl = this.baseUrl.endsWith("/")
      ? this.baseUrl.slice(0, -1)
      : this.baseUrl;
    const sanitizedEnpoint = endpoint.startsWith("/")
      ? endpoint.slice(1)
      : endpoint;
    const url = `${sanitizedBaseUrl}/${sanitizedEnpoint}`;

    const response = await fetch(
      queryString.stringifyUrl({
        url,
        query: {
          ...query,
          apikey: this.apiKey,
        },
      })
    );
    const data = await response.json();
    return data;
  };

  public fetchBlockDate = async (
    blockNumber: number | string
  ): Promise<number | string> => {
    try {
      const data = await this.get("/", {
        module: "block",
        action: "getblockcountdown",
        blockno: blockNumber,
      });

      if (data.status !== "1") {
        return "";
      }

      const estimatedInSec = data?.result?.EstimateTimeInSec;

      if (!estimatedInSec) {
        return "";
      }

      return Date.now() + estimatedInSec * 1000;
    } catch (e) {
      console.log("BLOCK_DATE_ERROR", e);
      return "";
    }
  };
}
