import { Gift } from "models";
import { ReactComponent as GiftIcon } from "assets/gift.svg";
import { formatRawUTC } from "helpers/date";

import { NoticeText } from "components/PoolCard/components";
import styles from "./GiftsModal.module.scss";

type GiftsModalProps = {
  gifts: Gift[];
};

const DATE_FORMAT = "DD MMM YYYY";

const GiftsModal = ({ gifts }: GiftsModalProps) => (
  <div className={styles.modal}>
    <h4 className={styles.title}>Congratulations!</h4>

    <div className={styles.successWrapper}>
      <GiftIcon height="200px" />
    </div>

    <div className={styles.desc}>You have won</div>

    <div className={styles.items}>
      {gifts.map((item) => (
        <div key={item.timestamp} className={styles.item}>
          <NoticeText label={`${item.amount} ${item.symbol}`} />
          <div style={{ marginTop: 40, fontSize: 14, textAlign: "center" }}>
            {formatRawUTC(Number(item.timestamp) * 1000, DATE_FORMAT)}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default GiftsModal;
