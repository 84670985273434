import { FC, useCallback } from "react";
import { Card } from "antd";
import { Pool, PoolState } from "models";
import { convertToPrice } from "helpers/formatting";
import { useModal } from "hooks";
import { usePoolMutations } from "hooks/mutations";
import {
  NumericText,
  Button,
  Modal,
  DepositModal,
  WithdrawModal,
  LoadingModal,
  PoolAvatar,
  Loader,
} from "components";

import { formatToDate } from "helpers/date";
import { openNoticeNotification } from "components/Notification";

import { DetailsText, NoticeText } from "./components";

import styles from "./PoolCard.module.scss";

interface PoolCardProps {
  pool: Pool;
  isLoading: boolean;
}

const PoolCard: FC<PoolCardProps> = (props) => {
  const { pool, isLoading } = props;
  const {
    totalStaked,
    farmingAPY,
    startDate,
    endDate,
    stake,
    reward,
    pendingReward,
    state,
    poolIndex,
    liquidityTokenAddress,
    contractAddress,
    depositToken,
    rewardToken,
    depositTokenPrice,
    rewardTokenPrice,
    withdrawTime,
    claimTime,
    noticeMessage,
  } = pool;

  const { claim, exit, claimLoading, withdrawLoading, exitLoading } =
    usePoolMutations(liquidityTokenAddress, contractAddress, poolIndex);

  const {
    isOpen: isOpenDeposit,
    onOpen: onOpenDeposit,
    onClose: onCloseDeposit,
  } = useModal();
  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useModal();
  const {
    isOpen: isOpenClaim,
    onOpen: onOpenClaim,
    onClose: onCloseClaim,
  } = useModal();
  const {
    isOpen: isOpenExit,
    onOpen: onOpenExit,
    onClose: onCloseExit,
  } = useModal();

  const claimModalTextCallback = useCallback(() => {
    onCloseClaim();
  }, [onCloseClaim]);

  const exitModalTextCallback = useCallback(() => {
    onCloseExit();
  }, [onCloseExit]);

  const isActive = state === PoolState.active;
  const isDepositDisabled = !isActive;
  const isWithdrawDisabled = Number(stake) <= 0;
  const isClaimDisabled = Number(pendingReward) <= 0;
  const isExitDisabled = isClaimDisabled && isWithdrawDisabled;

  const depositHandler = () => {
    if (!isDepositDisabled) {
      onOpenDeposit();
    }
  };

  const withdrawHandler = () => {
    if (!isWithdrawDisabled) {
      const isWithdrawAllowed = withdrawTime <= Date.now();
      if (isWithdrawAllowed) {
        onOpenWithdraw();
      } else {
        openNoticeNotification({
          message: `Withdrawing stake will be allowed ${formatToDate(
            withdrawTime
          )}`,
        });
      }
    }
  };

  const claimHandler = async () => {
    if (!isClaimDisabled) {
      const isClaimAllowed = claimTime <= Date.now();
      if (isClaimAllowed) {
        try {
          onOpenClaim();
          await claim({ callback: claimModalTextCallback });
        } catch (error) {
          console.log(error);
          onCloseClaim();
        }
      } else {
        openNoticeNotification({
          message: `Claiming reward will be allowed ${formatToDate(claimTime)}`,
        });
      }
    }
  };

  const exitHandler = async () => {
    if (!isExitDisabled) {
      const isWithdrawAllowed = withdrawTime <= Date.now();
      const isClaimAllowed = claimTime <= Date.now();
      if (isWithdrawAllowed && isClaimAllowed) {
        try {
          onOpenExit();
          await exit({ callback: exitModalTextCallback });
        } catch (error) {
          console.log(error);
          onCloseExit();
        }
      } else if (!isWithdrawAllowed) {
        openNoticeNotification({
          message: `Withdrawing stake will be allowed ${formatToDate(
            withdrawTime
          )}`,
        });
      } else {
        openNoticeNotification({
          message: `Claiming reward will be allowed ${formatToDate(claimTime)}`,
        });
      }
    }
  };

  return (
    <>
      <Card className={styles.card}>
        <header className={styles.cardHeader}>
          <PoolAvatar
            depositIcon={depositToken.icon}
            outcomeIcon={rewardToken.icon}
            size={{
              md: 32,
              xl: 52,
            }}
          />

          <div className={styles.titleWrapper}>
            <h5 className={styles.title}>
              Deposit {depositToken.symbol} to earn {rewardToken.symbol}
            </h5>
            {noticeMessage && <NoticeText label={noticeMessage} />}
          </div>

          <div className={styles.totalInfo}>
            <DetailsText label="Total Staked">
              {isLoading && <Loader small />}
              {!isLoading && (
                <NumericText
                  value={convertToPrice(totalStaked, depositTokenPrice)}
                  prefix="$"
                />
              )}
            </DetailsText>
            <DetailsText label="Farming APR">
              {isLoading && <Loader small />}
              {!isLoading && <NumericText value={farmingAPY} suffix="%" />}
            </DetailsText>
          </div>
        </header>

        <div className={styles.line} />

        <div className={styles.cardBody}>
          <div className={styles.dates}>
            {startDate && (
              <DetailsText label="Start date">{startDate}</DetailsText>
            )}
            <DetailsText label={state === PoolState.ended ? "" : "End date"}>
              {endDate}
            </DetailsText>
          </div>

          <div className={styles.totalInfo}>
            <DetailsText
              label="You Staked"
              hint="The total amount you've invested"
            >
              {isLoading && <Loader small />}
              {!isLoading && (
                <NumericText
                  value={convertToPrice(stake, depositTokenPrice)}
                  prefix="$"
                />
              )}
            </DetailsText>

            <DetailsText label="Reward">
              {isLoading && <Loader small />}
              {!isLoading && (
                <NumericText
                  value={convertToPrice(reward, rewardTokenPrice)}
                  prefix="$"
                />
              )}
            </DetailsText>
          </div>
        </div>

        <footer className={styles.cardFooter}>
          <div>
            <Button
              size="large"
              additionalText={depositToken.symbol}
              disabled={isDepositDisabled}
              onClick={depositHandler}
            >
              Deposit
            </Button>
          </div>

          <div>
            <Button
              size="large"
              additionalText={`${rewardToken.symbol} reward`}
              disabled={isClaimDisabled || claimLoading}
              onClick={claimHandler}
            >
              Claim
            </Button>
          </div>
          <div>
            <Button
              size="large"
              additionalText={`${depositToken.symbol} stake`}
              disabled={isWithdrawDisabled}
              onClick={withdrawHandler}
            >
              Withdraw
            </Button>
          </div>
          <div>
            <Button
              size="large"
              additionalText="Withdraw and Claim"
              disabled={
                isExitDisabled || exitLoading || claimLoading || withdrawLoading
              }
              onClick={exitHandler}
            >
              Exit
            </Button>
          </div>
        </footer>
      </Card>

      <Modal visible={isOpenDeposit} onCancel={onCloseDeposit} centered>
        <DepositModal pool={pool} />
      </Modal>

      <Modal visible={isOpenWithdraw} onCancel={onCloseWithdraw} centered>
        <WithdrawModal pool={pool} />
      </Modal>

      <Modal visible={isOpenClaim} onCancel={onCloseClaim} width={450} centered>
        <LoadingModal
          closeHandler={onCloseClaim}
          title="Claim Transaction"
          text="Please, sign transaction in your wallet"
        />
      </Modal>

      <Modal visible={isOpenExit} onCancel={onCloseExit} width={450} centered>
        <LoadingModal
          closeHandler={onCloseExit}
          title="Exit Transaction"
          text="Please, sign transaction in your wallet"
        />
      </Modal>
    </>
  );
};

export default PoolCard;
