import { v4 as uuidv4 } from "uuid";
import { notification } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { ReactComponent as Go } from "assets/action-go-white.svg";
import styles from "./Notification.module.scss";

// in seconds
const DEFAULT_DURATION = 10;

interface NotificationProps {
  title?: string;
  message?: string;
  url?: string;
  key?: string;
}

export const openSuccessNotification = (props: NotificationProps) => {
  const { title, message, url, key } = props;
  notification.open({
    className: `${styles.notification} ${styles.notification__success}`,
    icon: <CheckCircleOutlined className={styles.icon} />,
    message: <div className={styles.title}>{title}</div>,
    description: (
      <div className={styles.description}>
        {message && <div className={styles.message}>{message}</div>}
        {url && (
          <a
            className={styles.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.link__text}>View on explorer</span>
            <Go height={12} />
          </a>
        )}
      </div>
    ),
    duration: 0,
    key,
  });
};

export const openErrorNotification = (props: NotificationProps) => {
  const { title = "Error", message, url, key = uuidv4() } = props;
  const withTimer = !url;
  notification.open({
    className: `${styles.notification} ${styles.notification__error}`,
    icon: <CloseCircleOutlined className={styles.icon} />,
    message: <div className={styles.title}>{title}</div>,
    description: (
      <div className={styles.description}>
        {message && <div className={styles.message}>{message}</div>}
        {url && (
          <a
            className={styles.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.link__text}>View on explorer</span>
            <Go height={12} />
          </a>
        )}
        {withTimer && <div className={styles.timer} />}
      </div>
    ),
    duration: withTimer ? DEFAULT_DURATION : 0,
    key,
  });

  // sometimes notification is not being closed when its supposed to, so its workaround
  if (withTimer) {
    setTimeout(() => {
      notification.close(key);
    }, DEFAULT_DURATION * 1000);
  }
};

export const openNoticeNotification = (props: NotificationProps) => {
  const { title = "Notice", message, url, key = uuidv4() } = props;

  notification.open({
    className: `${styles.notification} ${styles.notification__notice}`,
    icon: <NotificationOutlined className={styles.icon} />,
    message: <div className={styles.title}>{title}</div>,
    description: (
      <div className={styles.description}>
        {message && <div className={styles.message}>{message}</div>}
        {url && (
          <a
            className={styles.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.link__text}>View on explorer</span>
            <Go height={12} />
          </a>
        )}
        <div className={styles.timer} />
      </div>
    ),
    duration: DEFAULT_DURATION,
    key,
  });

  // sometimes notification is not being closed when its supposed to, so its workaround
  setTimeout(() => {
    notification.close(key);
  }, DEFAULT_DURATION * 1000);
};

export const openLoadingNotification = (props: NotificationProps) => {
  const { title, message, url, key } = props;
  notification.open({
    className: `${styles.notification} ${styles.notification__loading}`,
    icon: <LoadingOutlined className={styles.icon} />,
    message: <div className={styles.title}>{title}</div>,
    description: (
      <div className={styles.description}>
        {message && <div className={styles.message}>{message}</div>}
        {url && (
          <a
            className={styles.link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={styles.link__text}>View on explorer</span>
            <Go height={12} />
          </a>
        )}
      </div>
    ),
    duration: 0,
    key,
  });
};

export const closeNotification = (key: string) => {
  notification.close(key);
};
