import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import ethereum from "utils/ethereum";
import useWallet from "hooks/useWallet";
import { WalletType, Snap } from "models";

const useSnap = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [snap, setSnap] = useState<Snap | null>(null);
  const { walletType } = useWallet();

  useEffect(() => {
    const checkIfSnapInstalled = async () => {
      if (walletType === WalletType.flask) {
        const installedSnap = await ethereum.getSnap();
        await new Promise((resolve) => {
          setTimeout(resolve, 300);
        });
        setSnap(installedSnap);
        setInitialLoading(false);
      }
    };

    checkIfSnapInstalled();
  }, [walletType]);

  const getSnap = useMutation(async () => {
    const installedSnap = await ethereum.getSnap();
    setSnap(installedSnap);
  });

  const installSnap = useMutation(async () => ethereum.installSnap());

  return {
    initialLoading,
    loading: installSnap.isLoading || getSnap.isLoading,
    snap,
    installSnap: installSnap.mutateAsync,
    getSnap: getSnap.mutateAsync,
  };
};

export default useSnap;
