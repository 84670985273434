import ProductionScanner from "helpers/ProductionScanner";

import {
  ETHEREUM_MAINNET_NETWORK,
  BSC_MAINNET_NETWORK,
  POLYGON_MAINNET_NETWORK,
  AURORA_MAINNET_NETWORK,
  RINKEBY_NETWORK,
  BSC_TEST_NETWORK,
  POLYGON_TESTNET_NETWORK,
  AURORA_TESTNET_NETWORK,
} from "./networks";

const IS_PRODUCTION = ProductionScanner.check();

const rpc = {
  [ETHEREUM_MAINNET_NETWORK.networkId]: ETHEREUM_MAINNET_NETWORK.rpcUrl,
  [BSC_MAINNET_NETWORK.networkId]: BSC_MAINNET_NETWORK.rpcUrl,
  [POLYGON_MAINNET_NETWORK.networkId]: POLYGON_MAINNET_NETWORK.rpcUrl,
  [AURORA_MAINNET_NETWORK.networkId]: AURORA_MAINNET_NETWORK.rpcUrl,
};

if (!IS_PRODUCTION) {
  rpc[RINKEBY_NETWORK.networkId] = RINKEBY_NETWORK.rpcUrl;
  rpc[BSC_TEST_NETWORK.networkId] = BSC_TEST_NETWORK.rpcUrl;
  rpc[POLYGON_TESTNET_NETWORK.networkId] = POLYGON_TESTNET_NETWORK.rpcUrl;
  rpc[AURORA_TESTNET_NETWORK.networkId] = AURORA_TESTNET_NETWORK.rpcUrl;
}

const WALLET_CONNECT_CONFIG = {
  infuraId: process.env.REACT_APP_INFURA_API_KEY,
  rpc,
};

export default WALLET_CONNECT_CONFIG;
