import clsx from "clsx";

import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";

import styles from "./PoolAvatar.module.css";

type PoolAvatarProps = {
  depositIcon: string;
  outcomeIcon: string;
  size?: AvatarSize;
};

const PoolAvatar = ({
  depositIcon,
  outcomeIcon,
  size = 52,
}: PoolAvatarProps) => (
  <>
    <Avatar
      size={size}
      className={styles.avatar}
      src={depositIcon}
      alt="deposit coin"
    />
    <Avatar
      size={size}
      className={clsx(styles.avatar, styles.avatarToken)}
      src={outcomeIcon}
      alt="outcome coin"
    />
  </>
);

export default PoolAvatar;
