import { ChangeEvent, FormEvent, useState, useRef } from "react";
import { Card, Col, Row } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { Pool } from "models";
import { convertToPrice } from "helpers/formatting";
import { NumericText, Button, PoolAvatar } from "components";
import { DetailsText, NoticeText } from "./components";

import styles from "./PoolCard.module.scss";

type SubscribeCardProps = {
  pool: Pool;
};

const SubscribeCard = ({ pool }: SubscribeCardProps) => {
  const {
    totalStaked,
    farmingAPY,
    startDate,
    stake,
    reward,
    depositToken,
    rewardToken,
    depositTokenPrice,
    rewardTokenPrice,
    noticeMessage,
  } = pool;

  const emailRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    emailRef.current?.setCustomValidity("");
    setEmail(e.target.value);
    setSubscribed(false);
  };

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!loading && !subscribed) {
      const isInvalid =
        !emailRef.current?.checkValidity() || !email.includes(".");
      if (isInvalid) {
        if (!email.includes(".")) {
          emailRef.current?.setCustomValidity(
            "Please include a '.' in the email address."
          );
        }
        emailRef.current?.reportValidity();
      } else {
        setLoading(true);
        try {
          const response = await fetch(
            "https://issuer.app.purefi.io/subscribe",
            {
              method: "POST",
              body: JSON.stringify({ email: email.trim() }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if ("status" in data) {
            setSubscribed(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Card className={styles.card}>
        <header className={styles.cardHeader}>
          <PoolAvatar
            depositIcon={depositToken.icon}
            outcomeIcon={rewardToken.icon}
            size={{
              md: 32,
              xl: 52,
            }}
          />

          <div className={styles.titleWrapper}>
            <h5 className={styles.title}>
              Deposit {depositToken.symbol} to earn {rewardToken.symbol}
            </h5>
            {noticeMessage && <NoticeText label={noticeMessage} />}
          </div>

          <div className={styles.totalInfo}>
            <DetailsText label="Total Staked">
              <NumericText
                value={convertToPrice(totalStaked, depositTokenPrice)}
                prefix="$"
              />
            </DetailsText>
            <DetailsText label="Farming APR">
              <NumericText value={farmingAPY} suffix="%" />
            </DetailsText>
          </div>
        </header>

        <div className={styles.line} />

        <div className={styles.cardBody}>
          <div className={styles.dates}>
            {startDate && (
              <DetailsText label="Start date">{startDate}</DetailsText>
            )}
          </div>

          <div className={styles.totalInfo}>
            <DetailsText
              label="You Staked"
              hint="The total amount you've invested"
            >
              <NumericText
                value={convertToPrice(stake, depositTokenPrice)}
                prefix="$"
              />
            </DetailsText>

            <DetailsText label="Reward">
              <NumericText
                value={convertToPrice(reward, rewardTokenPrice)}
                prefix="$"
              />
            </DetailsText>
          </div>
        </div>

        <footer className={styles.cardFooter}>
          <form className={styles.form} onSubmit={submitHandler}>
            <Row>
              <Col>
                <div className={styles.inputLabel}>
                  Sign up for the highest APY
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 24]} justify="space-between" align="middle">
              <Col
                className={styles.inputContainer}
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 18, offset: 0 }}
              >
                <input
                  className={styles.emailInput}
                  name={email}
                  ref={emailRef}
                  type="email"
                  onChange={changeHandler}
                  placeholder="Enter your email"
                  required
                />
              </Col>
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 5, offset: 1 }}>
                <Button
                  type="submit"
                  className={styles.submitButton}
                  size="middle"
                >
                  <span className={styles.buttonText}>
                    <span className={styles.mainText}>
                      {!loading ? (
                        "Sign up"
                      ) : (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                      )}
                    </span>

                    {subscribed && (
                      <CheckCircleOutlined style={{ fontSize: 20 }} />
                    )}
                  </span>
                </Button>
              </Col>
            </Row>
          </form>
        </footer>
      </Card>
    </>
  );
};

export default SubscribeCard;
