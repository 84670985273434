import { ReactNode, useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletConnect from "@walletconnect/client";

import ethereum from "utils/ethereum";
import storage from "helpers/storage";
import { WalletStatus, WalletType, Network } from "models";
import { DEFAULT_NETWORK, NETWORKS, WALLET_CONNECT_CONFIG } from "config";

import WalletContext from "./WalletContext";

type WallerProviderProps = {
  children: ReactNode;
};

const METAMASK_WALLET_STORAGE_KEY = "metamask";

const WalletProvider = ({ children }: WallerProviderProps) => {
  const queryClient = useQueryClient();
  const [account, setAccount] = useState<string>("");
  const [status, setStatus] = useState(WalletStatus.connecting);
  const [balance, setBalance] = useState<string>("");
  const [networkId, setNetworkId] = useState<number | null>(null);
  const [activeNetwork, setActiveNetwork] = useState<Network>(DEFAULT_NETWORK);
  const [walletType, setWalletType] = useState<WalletType>(WalletType.none);

  const refetchQueries = useCallback(async () => {
    // Re-fetch all queries after success wallet connection
    await queryClient.invalidateQueries();
  }, [queryClient]);

  const setWalletAccount = useCallback(
    async (newAccount: string) => {
      if (newAccount) {
        const accountBalance = await ethereum.getBalance();

        setBalance(accountBalance);
        setStatus(WalletStatus.connected);
      } else {
        setBalance("");
        setStatus(WalletStatus.disconnected);
        setNetworkId(null);

        storage.removeValue(METAMASK_WALLET_STORAGE_KEY);
      }

      setAccount(newAccount);
      await refetchQueries();
    },
    [refetchQueries]
  );

  const setWalletNetworkId = useCallback(
    async (chainId) => {
      setNetworkId(chainId);
      const network = NETWORKS[chainId];

      if (network) {
        setActiveNetwork(network);
      }

      const accountBalance = await ethereum.getBalance();
      setBalance(accountBalance);

      await refetchQueries();
    },
    [refetchQueries]
  );

  const connect = useCallback(
    async (type: WalletType, initialization = false) => {
      try {
        const requestWalletModal = !initialization;

        if (type === WalletType.metamask) {
          const isWalletInstalled = await ethereum.isMetaMaskProviderExist();

          if (isWalletInstalled) {
            const walletAccount = await ethereum.getMetaMaskWalletAccount(
              requestWalletModal
            );

            ethereum.setWalletAccount(walletAccount);
            await setWalletAccount(walletAccount);
            ethereum.init(null, setWalletAccount, setWalletNetworkId);
            storage.addValue(METAMASK_WALLET_STORAGE_KEY, "true");

            const isFlask = await ethereum.detectFlask();
            const clarifiedType = isFlask
              ? WalletType.flask
              : WalletType.metamask;
            setWalletType(clarifiedType);
          }
        }

        if (type === WalletType.walletConnect) {
          const provider = new WalletConnectProvider({
            ...WALLET_CONNECT_CONFIG,
            qrcode: requestWalletModal,
            chainId: activeNetwork.networkId,
          });

          await provider.enable();
          const walletAccount = provider.accounts[0] || "";

          ethereum.setWalletAccount(walletAccount);
          ethereum.init(provider, setWalletAccount, setWalletNetworkId);
          await setWalletAccount(walletAccount);
          setWalletType(type);
        }
      } catch (e) {
        console.log(e);
        setStatus(WalletStatus.disconnected);
      }
    },
    [setWalletAccount, setWalletNetworkId, activeNetwork]
  );

  const disconnect = useCallback(async () => {
    if (walletType === WalletType.metamask || walletType === WalletType.flask) {
      storage.removeValue(METAMASK_WALLET_STORAGE_KEY);
    }

    if (walletType === WalletType.walletConnect) {
      const provider = new WalletConnectProvider(WALLET_CONNECT_CONFIG);
      await provider.disconnect();
    }

    setWalletType(WalletType.none);
    setAccount("");
    setStatus(WalletStatus.disconnected);
  }, [walletType]);

  const init = useCallback(async () => {
    // Check MetaMask connection in storage and then check the provider
    const isMetaMaskWalletConnected =
      storage.getValue(METAMASK_WALLET_STORAGE_KEY) &&
      (await ethereum.isMetaMaskProviderExist());

    // Check WalletConnect connection flag
    const isWalletConnectConnected = new WalletConnect({
      bridge: "https://bridge.walletconnect.org",
    }).connected;

    if (isWalletConnectConnected) {
      await connect(WalletType.walletConnect, true);
    } else if (isMetaMaskWalletConnected) {
      await connect(WalletType.metamask, true);
    } else {
      setStatus(WalletStatus.disconnected);
    }
  }, [setWalletAccount, setWalletNetworkId]);

  const handleNetworkChange = useCallback(
    async (network: Network) => {
      const flag = await ethereum.setNetwork(network);

      if (flag) {
        setNetworkId(network.networkId);
        setActiveNetwork(network);
      }

      if (walletType === WalletType.walletConnect) {
        await disconnect();
      } else {
        await refetchQueries();
      }
    },
    [walletType, disconnect, refetchQueries]
  );

  useEffect(() => {
    init();
  }, [init]);

  return (
    <WalletContext.Provider
      value={{
        account,
        connect,
        status,
        balance,
        networkId,
        walletType,
        disconnect,
        activeNetwork,
        onNetworkChange: handleNetworkChange,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
