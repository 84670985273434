import React from "react";
import { Row, Col } from "antd";
import { Button, Loader } from "components";
import { WalletType } from "models";
import { useSnap } from "hooks/mutations";
import { useWallet } from "hooks";
import styles from "./SnapDemo.module.scss";

const SnapDemo = () => {
  const { walletType } = useWallet();

  const { initialLoading, loading, snap, installSnap, getSnap } = useSnap();

  const connectHandler = async () => {
    await installSnap();
    await getSnap();
  };

  if (walletType !== WalletType.flask) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>PureFI Snap Demo</h2>

      <Row>
        {initialLoading && (
          <Col span={24}>
            <div className={styles.item}>Loading...</div>
          </Col>
        )}
        {!initialLoading && (
          <>
            {snap !== null && (
              <Col span={24}>
                <div className={styles.item}>
                  You are successfully connected to PureFI snap
                </div>
                <div className={styles.item} style={{ fontSize: 14 }}>
                  To see snap in action initiate a transaction
                </div>
              </Col>
            )}
            {snap === null && (
              <Col span={24}>
                <Button
                  className={styles.button}
                  onClick={connectHandler}
                  disabled={loading}
                >
                  {loading && <Loader small white />}
                  <span style={{ marginLeft: loading ? 10 : 0 }}>
                    Connect to PureFI Demo Snap
                  </span>
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default SnapDemo;
