import { FC, ReactNode } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "components";
import styles from "./DetailsText.module.scss";

type DetailsTextProps = {
  label: string;
  children: ReactNode;
  hint?: string;
};

const DetailsText: FC<DetailsTextProps> = (props) => {
  const { children, label, hint } = props;

  return (
    <div className={styles.detailsText}>
      <p className={styles.label}>
        {label}
        {hint && (
          <Tooltip overlay={hint}>
            <span className={styles.hint}>
              <QuestionCircleOutlined style={{ color: "#983eff" }} />
            </span>
          </Tooltip>
        )}
      </p>
      <p className={styles.text}>{children}</p>
    </div>
  );
};

export default DetailsText;
