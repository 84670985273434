import { FC, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import RcTooltip from "rc-tooltip";
import styles from "./Tooltip.module.scss";

type DirectionType = "left" | "top" | "bottom" | "right";

interface TooltipProps {
  overlay: ReactElement | string;
  children: ReactElement | ReactNode;
  direction?: DirectionType;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { overlay, children, direction = "top" } = props;

  const wrappedOverlay = <>{overlay}</>;
  const wrappedChildren = <span>{children}</span>;

  const tooltipClassName = clsx(styles.tooltip);

  return (
    <RcTooltip
      prefixCls={tooltipClassName}
      trigger="hover"
      placement={direction}
      overlay={wrappedOverlay}
      destroyTooltipOnHide
    >
      {wrappedChildren}
    </RcTooltip>
  );
};

export default Tooltip;
