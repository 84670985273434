import { useCallback, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { errorCodes, serializeError, EthereumRpcError } from "eth-rpc-errors";
import { VerifyRuleResponse } from "@purefi/verifier-sdk";
import {
  closeNotification,
  openErrorNotification,
  openLoadingNotification,
  openSuccessNotification,
} from "components/Notification";

import ethereum from "utils/ethereum";
import { ethers } from "ethers";
import { WalletContext } from "context";
import { VerifiableDesc } from "models";

type CustomError = {
  code?: number;
  message?: string;
  originalError?: {
    reason: string;
    error?: {
      message: string;
    };
  };
};

type ApproveVariables = {
  callback: () => void;
};

type VerifyRuleVariables = {
  value: number | string;
  verifiableDesc: VerifiableDesc;
  callback: () => void;
};

type DepositVariables = {
  value: number | string;
  callback: () => void;
  ruleResponse?: VerifyRuleResponse;
  verifiableDesc?: VerifiableDesc;
};

type WithdrawVariables = {
  value: number | string;
  callback: () => void;
};

type ClaimVariables = {
  callback: () => void;
};

type ExitVariables = {
  callback: () => void;
};

const usePoolMutations = (
  liquidityTokenAddress: string,
  contractAddress: string,
  poolIndex: number
) => {
  const wallet = useContext(WalletContext);
  const queryClient = useQueryClient();

  const refetchQuery = useCallback(() => {
    queryClient.invalidateQueries("pools");
  }, [queryClient]);

  const [verifyRuleLoading, setVerfyRuleLoading] = useState(false);

  const handleError = useCallback((error) => {
    const rpcError = error as EthereumRpcError<CustomError>;
    const { code, data, message: rpcErrorMessage } = serializeError(rpcError);

    const errorData = data as CustomError;

    if (code === errorCodes.provider.userRejectedRequest) {
      openErrorNotification({
        message: "Transaction has been canceled",
      });
    } else if (
      code === errorCodes.rpc.internal &&
      errorData?.message?.includes("insufficient funds")
    ) {
      // error message example
      // err: insufficient funds for gas * price + value: address 0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB have 61177490000000000 want 10000000000000000000 (supplied gas 10128300)
      let message = "You have insufficient funds to pay commission";

      const strFeeInWei = errorData.message
        .split("want")[1]
        ?.trim()
        ?.split(" ")[0];

      const parsedFeeInWei = parseInt(strFeeInWei, 10);

      if (!Number.isNaN(parsedFeeInWei)) {
        const ethValue = Number(ethers.utils.formatEther(strFeeInWei));
        message += `. At least ${ethValue} ${wallet.activeNetwork.nativeCurrency.symbol} is required`;
      }

      openErrorNotification({ message });
    } else {
      let message = rpcErrorMessage;
      if (errorData.message) {
        const parts = errorData.message.split(":");
        const theLastPart = parts[parts.length - 1].trim();
        const capitalizedTheLastPart =
          theLastPart[0].toUpperCase() + theLastPart.slice(1);

        const patchedRpcErrorMessage = rpcErrorMessage.endsWith(".")
          ? rpcErrorMessage.slice(0, -1)
          : rpcErrorMessage;
        message = `${patchedRpcErrorMessage}\n${capitalizedTheLastPart}`;
      }
      openErrorNotification({ title: "Transaction rejected", message });
    }
  }, []);

  const handleSuccess = useCallback(
    (title: string, url: string) => {
      refetchQuery();

      setTimeout(() => {
        openSuccessNotification({
          title,
          url,
        });
      }, 500);
    },
    [refetchQuery]
  );

  const handleTransaction = useCallback(
    async (mutationFn, callback?: () => void) => {
      const transactionResponse = await mutationFn;
      const link = ethereum.getExplorerLink(transactionResponse.hash);

      const notificationKey = uuidv4();
      openLoadingNotification({
        title: "Transaction is in progress",
        url: link,
        key: notificationKey,
      });

      if (typeof callback === "function") {
        callback();
      }

      await transactionResponse.wait();

      closeNotification(notificationKey);

      return link;
    },
    []
  );

  const approve = useMutation(
    async (variables: ApproveVariables) =>
      handleTransaction(
        ethereum.approve(liquidityTokenAddress, contractAddress),
        variables.callback
      ),
    {
      onSuccess: (url: string) => handleSuccess("Successful Approval", url),
      onError: handleError,
    }
  );

  const verifyRule = async (variables: VerifyRuleVariables) => {
    try {
      setVerfyRuleLoading(true);
      const response = await ethereum.verifyRule(
        variables.value,
        liquidityTokenAddress,
        variables.verifiableDesc,
        variables.callback
      );
      return response;
    } finally {
      setVerfyRuleLoading(false);
    }
  };

  const deposit = useMutation(
    async (variables: DepositVariables) =>
      handleTransaction(
        ethereum.deposit(
          variables.value,
          liquidityTokenAddress,
          contractAddress,
          poolIndex,
          variables.ruleResponse,
          variables.verifiableDesc
        ),
        variables.callback
      ),
    {
      onSuccess: (url: string) => handleSuccess("Successful Deposit", url),
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async (variables: WithdrawVariables) =>
      handleTransaction(
        ethereum.withdraw(
          variables.value,
          liquidityTokenAddress,
          contractAddress,
          poolIndex
        ),
        variables.callback
      ),
    {
      onSuccess: (url: string) => handleSuccess("Successful Withdrawal", url),
      onError: handleError,
    }
  );

  const claim = useMutation(
    async (variables: ClaimVariables) =>
      handleTransaction(
        ethereum.claim(contractAddress, poolIndex),
        variables.callback
      ),
    {
      onSuccess: (url: string) => handleSuccess("Successful Claim", url),
      onError: handleError,
    }
  );

  const exit = useMutation(
    async (variables: ExitVariables) =>
      handleTransaction(
        ethereum.exit(contractAddress, poolIndex),
        variables.callback
      ),
    {
      onSuccess: (url: string) => handleSuccess("Successful Exit", url),
      onError: handleError,
    }
  );

  return {
    approveLoading: approve.isLoading,
    verifyRuleLoading,
    depositLoading: deposit.isLoading,
    withdrawLoading: withdraw.isLoading,
    claimLoading: claim.isLoading,
    exitLoading: exit.isLoading,
    loading:
      approve.isLoading ||
      verifyRuleLoading ||
      deposit.isLoading ||
      withdraw.isLoading ||
      claim.isLoading ||
      exit.isLoading,
    approve: approve.mutateAsync,
    verifyRule,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
    claim: claim.mutateAsync,
    exit: exit.mutateAsync,
  };
};

export default usePoolMutations;
