import { ReactNode, MouseEventHandler } from "react";
import clsx from "clsx";

import { Button as AntdButton } from "antd";

import styles from "./Button.module.css";

type ButtonProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
  icon?: ReactNode;
  disabled?: boolean;
  size?: "middle" | "large";
  additionalText?: string;
  rel?: string;
  href?: string;
  target?: string;
  type?: "button" | "submit" | "reset";
  "data-testid"?: string;
};

const Button = ({
  children,
  onClick,
  className = "",
  icon,
  disabled = false,
  size = "middle",
  additionalText = "",
  rel = "",
  href,
  target,
  type = "button",
  "data-testid": testId,
}: ButtonProps) => (
  <AntdButton
    onClick={onClick}
    className={clsx(styles.button, className)}
    icon={icon}
    disabled={disabled}
    size={size}
    href={href}
    target={target}
    htmlType={type}
    rel={rel}
    data-testid={testId}
  >
    {children}

    {additionalText && (
      <div className={styles.additionalText}>{additionalText}</div>
    )}
  </AntdButton>
);

export default Button;
