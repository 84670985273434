import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./Loader.module.scss";

type LoaderProps = {
  small?: boolean;
  white?: boolean;
};

const Loader: FC<LoaderProps> = (props) => {
  const { small = false, white = false } = props;

  const spinnerClassName = `${styles.spin} ${
    small ? styles.spin__small : styles.spin__big
  } ${white ? styles.spin__white : ""}`;

  return (
    <Spin indicator={<LoadingOutlined spin />} className={spinnerClassName} />
  );
};

export default Loader;
